import Box from "@mui/material/Box";
import Dropdown from "@/common/components/dropdown/Dropdown";
import { CallLogFiltersInput } from "..";
import { AiVoiceCallFlowOutcome } from "@/types/ai-voice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { DatePicker } from "@/common/components/date-picker/DatePicker";
import { getTeamUsers } from "@/redux/reducers/settings/team/get";

type CallLogFiltersProps = {
  filters: CallLogFiltersInput;
  setFilters: (filters: CallLogFiltersInput | ((prevFilters: CallLogFiltersInput) => CallLogFiltersInput)) => void;
};

type FilterOption = {
  label: string;
  value: string;
};

export const CALL_OUTCOME_SELECT_OPTIONS: { label: string; value: AiVoiceCallFlowOutcome }[] = [
  { label: "Error", value: "ERROR" },
  { label: "Inbound", value: "INBOUND" },
  { label: "DNC", value: "OUTBOUND_DNC" },
  { label: "Voicemail", value: "OUTBOUND_VOICEMAIL" },
  { label: "Answered", value: "OUTBOUND_ANSWERED" },
  { label: "Booked", value: "BOOKED" },
  { label: "Transferred", value: "TRANSFER" },
  { label: "Transferred - Sales", value: "TRANSFER_SALES" },
  { label: "Transferred - Billing", value: "TRANSFER_BILLING" },
  { label: "Transferred - Support", value: "TRANSFER_SUPPORT" },
];

export default function CallLogFilters({ filters, setFilters }: CallLogFiltersProps) {
  const teamUsers = useAppSelector((selector) => selector.settings.team.users);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>("");

  const assigneesOptions = useMemo(() => {
    return (teamUsers || []).map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.user_id,
    }));
  }, [teamUsers]);

  useEffect(() => {
    dispatch(getTeamUsers());
  }, [dispatch]);

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value: string) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          search: value,
        }));
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    debouncedSetFilters(search);

    return () => debouncedSetFilters.cancel();
  }, [debouncedSetFilters, search]);

  const handleChange = (option: FilterOption[]) => {
    if (option.length > 0) {
      setFilters((previousFilters) => ({
        ...previousFilters,
        assignee: option.map((option) => option.value),
      }));
    } else {
      setFilters((previousFilters) => ({ ...previousFilters, assignee: undefined }));
    }
  };

  return (
    <form>
      <Box
        sx={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: "16px",
        }}
      >
        <Searchbar
          placeholder="Search by Contact or Phone Number"
          value={search}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(value);
          }}
        />

        <Box sx={{ width: "100%" }}>
          <Dropdown
            label="Assignee"
            placeholder="Assignee"
            options={assigneesOptions}
            isMulti
            value={filters.assignee as any}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Dropdown
            label="Outcome"
            placeholder="Outcome"
            options={[...CALL_OUTCOME_SELECT_OPTIONS]}
            onChange={(option) => {
              setFilters({ ...filters, callOutcome: option.value });
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <DatePicker
            range={true}
            value={[filters?.callEndedAtStart, filters?.callEndedAtEnd] as any}
            onChange={(formattedDates: any) => {
              if (formattedDates?.dateStart && formattedDates?.dateEnd) {
                setFilters((prev) => ({
                  ...prev,
                  callEndedAtStart: new Date(formattedDates?.dateStart),
                  callEndedAtEnd: new Date(formattedDates?.dateEnd),
                }));
              } else {
                setFilters((prev) => ({
                  ...prev,
                  callEndedAtStart: undefined,
                  callEndedAtEnd: undefined,
                }));
              }
            }}
          />
        </Box>
      </Box>
    </form>
  );
}
