import { RootState } from "@/redux/store";
import Services from "@/services";
import { SequenceResponse } from "@/services/generated";
import { SequenceTemplate, SequenceFiltersType } from "@/types/sequence";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sortBy } from "lodash";
import { sortByParentsIds } from "./helpers";

type State = {
  sequences?: SequenceTemplate[];
  filters: SequenceFiltersType;
  loading: boolean;
  error: string | null;
  createDrawerIsOpen: boolean;
  currentSequence: SequenceTemplate | null;
  prospectSequence: SequenceResponse[];
};

const initialState: State = {
  loading: false,
  error: null,
  createDrawerIsOpen: false,
  currentSequence: null,
  filters: {},
  prospectSequence: [],
};

export const DEFAULT_STATUS_FILTER: ("ACTIVE" | "DRAFT" | "INACTIVE")[] = ["ACTIVE", "DRAFT", "INACTIVE"];

export const getSequencesByContact = createAsyncThunk(
  "sequences/getSequencesByContact",
  async (contactId: string, { getState, rejectWithValue }) => {
    try {
      const response = await Services.Sequences.getSequencesByContact(contactId);

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getSequenceById = createAsyncThunk(
  "sequences/getSequenceById",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await Services.SequenceTemplates.getSequenceTemplate(id);

      if (response.status === 200 && response.data.sequenceTemplate && response.data.steps) {
        const data = {
          sequence: {
            id: response.data.sequenceTemplate.id!,
            status: response.data.sequenceTemplate.status!,
            name: response.data.sequenceTemplate.name!,
            createdAt: response.data.sequenceTemplate.createdAt!,
            updatedAt: response.data.sequenceTemplate.updatedAt!,
            originalSequenceTemplateId: response.data.sequenceTemplate.originalSequenceTemplateId,
            steps: (response.data.steps || []).sort(sortByParentsIds).map((step) => ({
              id: step.id!,
              delay: step.delayInMillis! / (1000 * 60 * 60 * 24),
              actionType: step.type!,
              content: step.messageTemplate,
              aiAgentId: step.agentId,
              parentsIds: step.parentsIds!,
            })),
            versions: response.data.versions as any,
          },
        };

        return data;
      } else {
        return rejectWithValue(response.statusText);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getSequencesTemplates = createAsyncThunk(
  "sequences/getSequencesTemplates",
  async (_, { getState, rejectWithValue }) => {
    const appState = getState() as RootState;
    const sequenceFilters = appState.sequences.listSequences.filters;
    const { status = DEFAULT_STATUS_FILTER, name } = sequenceFilters;

    try {
      const response = await Services.SequenceTemplates.getSequenceTemplatesForUserGroupedByOriginal(name, status);

      const sequenceList: SequenceTemplate[] = sortBy(response.data, "updatedAt", "desc").map(
        ({
          sumProspectsEnrolled,
          originalSequenceTemplateId,
          name,
          createdBy,
          createdAt,
          sequenceTemplateVersionsList,
          latestStatus,
          updatedAt,
          latestSequenceTemplateId,
        }) => ({
          id: originalSequenceTemplateId!,
          prospectEnrolled: sumProspectsEnrolled!,
          status: latestStatus!,
          name: name!,
          createdBy: { name: createdBy!.name!, imageUrl: createdBy!.imageUrl! },
          createdAt: createdAt!,
          updatedAt: updatedAt!,
          latestSequenceTemplateId,
          versions: sortBy(sequenceTemplateVersionsList || [], "updatedAt", "desc").map((version) => ({
            id: version.id!,
            prospectEnrolled: version.countProspectsEverEnrolled!,
            status: version.status!,
            name: version.name!,
            version: version.version,
            originalSequenceTemplateId,
            createdBy: { name: version.createdBy!.name!, imageUrl: version.createdBy!.imageUrl! },
            updatedAt: version.updatedAt!,
            createdAt: version.createdAt!,
          })),
        }),
      );

      return sequenceList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const listSequencesSlice = createSlice({
  name: "listSequences",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSequencesTemplates.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getSequencesTemplates.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.sequences = payload;
    });

    builder.addCase(getSequencesByContact.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getSequencesByContact.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.status === 200) state.prospectSequence = payload.data;
      else state.prospectSequence = [];
    });

    builder.addCase(getSequencesTemplates.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload as string;
    });

    builder.addCase(getSequenceById.fulfilled, (state, { payload }) => {
      // getTemplateById only has 3 fields
      state.currentSequence = payload.sequence;
    });
  },
  reducers: {
    setCurrentSequence: (state, action: PayloadAction<{ sequence: SequenceTemplate | null }>) => {
      state.currentSequence = action.payload.sequence;
    },
    updateCurrentSequence: (state, action: PayloadAction<{ sequence: Partial<SequenceTemplate> }>) => {
      const { sequence } = action.payload;

      if (state.currentSequence) {
        state.currentSequence = Object.assign(state.currentSequence, sequence);
      }
    },
    addNewSequence: (state, action: PayloadAction<{ sequence: SequenceTemplate }>) => {
      if (!state.sequences) {
        state.sequences = [action.payload.sequence];
      } else {
        state.sequences.push(action.payload.sequence);
      }
    },
    updateSequenceById: (state, action: PayloadAction<{ id: string; data: Partial<SequenceTemplate> }>) => {
      const { id, data } = action.payload;
      state.sequences = state.sequences?.map((sequence) => {
        if (sequence.id === id) {
          const newSequence: SequenceTemplate = { ...sequence, ...data };
          return newSequence;
        } else {
          return sequence;
        }
      });
    },
    deleteSequenceById: (state, action) => {
      const { id } = action.payload;
      state.sequences = state.sequences?.filter((sequence) => sequence.id !== id);
    },
    deleteSequenceVersionById: (state, action: PayloadAction<{ id: string; versionId: string }>) => {
      const { id, versionId } = action.payload;
      state.sequences = state.sequences?.map((sequence) => {
        if (sequence.id === id) {
          return { ...sequence, actions: (sequence.versions || []).filter((action) => action.id !== versionId) };
        } else {
          return sequence;
        }
      });
    },
    setSequenceFilters: (state, action: PayloadAction<SequenceFiltersType>) => {
      const newFilters = action.payload;
      state.filters = { ...state.filters, ...newFilters };
    },
  },
});

export const {
  addNewSequence,
  updateSequenceById,
  deleteSequenceById,
  setCurrentSequence,
  updateCurrentSequence,
  setSequenceFilters,
} = listSequencesSlice.actions;

export default listSequencesSlice.reducer;
