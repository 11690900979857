import {
  CreateBrandRequestDto,
  CreateBrandRequestDtoEntityTypeEnum,
  CreateBrandRequestDtoVerticalEnum,
} from "@/services/generated";

export interface BrandRegistrationFormValues {
  status?: "OK" | "REGISTRATION_PENDING" | "REGISTRATION_FAILED";
  brand_id?: string;
  businessName: string;
  businessType: { label: string; value: string };
  taxId: string;
  businessUrl: string;
  businessSupportEmail: string;
  businessIndustry: { label: string; value: string };
  businessAddress: string;
  businessAddress2?: string;
  businessCity?: string;
  businessState: { label: string; value: string };
  businessPostalCode?: string;
  businessCountry: { label: string; value: string };
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  contactJobTitle: string;
}

export interface BrandRegistrationResponse {
  ein: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  country: string;
  email: string;
  website: string | null;
  vertical: string;
  mock: boolean;
  status: "OK" | "REGISTRATION_PENDING" | "REGISTRATION_FAILED";
  entity_type: string | null;
  csp_id: string | null;
  brand_id: string;
  display_name: string | null;
  company_name: string | null;
  first_name: string | null;
  last_name: string | null;
  mobile_phone: string | null;
  postal_code: string | null;
  stock_symbol: string | null;
  stock_exchange: string | null;
  brand_relationship: string | null;
  alt_business_id: string | null;
  alt_business_id_type: string | null;
  universal_ein: string | null;
  reference_id: string | null;
  identity_status: string | null;
  ip_address: string | null;
  tcr_brand_id: string | null;
  is_reseller: boolean;
  webhook_url: string | null;
  webhook_failover_url: string | null;
  failure_reasons: string | null;
}

export const mapBrandRegistrationResponse = (response: BrandRegistrationResponse): BrandRegistrationFormValues => {
  return {
    status: response.status,
    brand_id: response.brand_id,
    businessName: response.company_name || "",
    businessType: { label: response.entity_type || "", value: response.entity_type || "" },
    taxId: response.ein || "",
    businessUrl: response.website || "",
    businessSupportEmail: response.email || "",
    businessIndustry: { label: response.vertical, value: response.vertical },
    businessAddress: response.street || "",
    businessAddress2: response.street || "",
    businessCity: response.city || "",
    businessState: { label: response.state || "", value: response.state || "" },
    businessPostalCode: response.postal_code || "",
    businessCountry: { label: response.country || "", value: response?.country || "" },
    contactFirstName: response.first_name || "",
    contactLastName: response.last_name || "",
    contactEmail: response.email || "",
    contactPhone: response.phone || "",
    contactJobTitle: response.entity_type || "",
  };
};

export const mapBrandRegistrationRequest = (state: BrandRegistrationFormValues): CreateBrandRequestDto => {
  return {
    company_name: state.businessName,
    display_name: state.contactFirstName + " " + state.contactLastName,
    vertical: state.businessIndustry.value as CreateBrandRequestDtoVerticalEnum,
    ein: state.taxId,
    website: state.businessUrl,
    email: state.businessSupportEmail,
    street: state.businessAddress,
    city: state.businessCity,
    state: state.businessState.value,
    postal_code: state.businessPostalCode,
    country: state.businessCountry.value,
    first_name: state.contactFirstName,
    last_name: state.contactLastName,
    phone: state.contactPhone,
    entity_type: state.businessType.value as CreateBrandRequestDtoEntityTypeEnum,
  };
};
