import Box from "@mui/material/Box";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { TaskStatusSelectOptions, TaskTypeSelectOptions } from "@/redux/reducers/tasks/slices/helpers";
import { FormControl } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { TaskFiltersInput } from "..";
import { TaskRequestStatusEnum } from "@/services/generated";
import { DatePicker } from "@/common/components/date-picker/DatePicker";

export type TaskFiltersProps = {
  filters: TaskFiltersInput | undefined;
  setFilters: (filters: TaskFiltersInput | ((prevFilters: TaskFiltersInput) => TaskFiltersInput)) => void;
};

export default function TaskFilters({ filters, setFilters }: TaskFiltersProps) {
  const [prospectName, setProspectName] = useState("");

  // const dispatch = useAppDispatch();
  // const { totalCount, loadingTasks } = useAppSelector(({ tasks }) => tasks.listTask);

  // const handleResetFilters = () => {
  //   dispatch(getTasks());
  //   setFilters({});
  // };

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value) => {
        setFilters((prev) => ({ ...prev, prospectName: value }));
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    debouncedSetFilters(prospectName);
  }, [debouncedSetFilters, prospectName]);

  // if (loadingTasks || totalCount === 0)
  //   return (
  //     <>
  //       <div style={{ width: "100%" }}>
  //         <Button variant="secondary" onClick={handleResetFilters} size="sm">
  //           Reset filters
  //         </Button>
  //       </div>
  //     </>
  //   );

  return (
    <form>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: "16px",
        }}
      >
        <Searchbar
          placeholder="Search by prospect"
          value={prospectName}
          onChange={({ target: { value } }: any) => {
            setProspectName(value);
          }}
        />

        <Box>
          <Dropdown
            label="Task Type"
            placeholder="Task Type"
            options={TaskTypeSelectOptions.filter((o) => o.show)}
            onChange={({ value }: { value: string }) => {
              setFilters((prev: any) => ({ ...prev, type: value }));
            }}
          />
        </Box>

        <Box>
          <Dropdown
            label="Status"
            placeholder="Status"
            options={[
              ...TaskStatusSelectOptions.map((option) => ({
                label: option.label.toLocaleLowerCase().replace(/^\w/, (c) => c.toUpperCase()),
                value: option.value,
              })),
            ]}
            onChange={({ value }: { value: string }) => {
              setFilters((prev) => ({ ...prev, status: value as TaskRequestStatusEnum }));
            }}
          />
        </Box>
        <Box>
          <FormControl sx={{ width: "100%" }}>
            <DatePicker
              range={true}
              value={[filters?.dueDateStart, filters?.dueDateEnd] as any}
              onChange={(formattedDates: any) => {
                if (typeof formattedDates === "object") {
                  setFilters((prev) => ({
                    ...prev,
                    dueDateStart: formattedDates?.dateStart,
                    dueDateEnd: formattedDates?.dateEnd,
                  }));
                }
              }}
            />
          </FormControl>
        </Box>
      </Box>
    </form>
  );
}
