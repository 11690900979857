import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import CreateDrawer from "../create/CreateDrawer";
import { getProducts, openCreateProductDrawer } from "@/redux/reducers/productLibrary/slices/listProducts";
import Button from "@/common/components/button";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useTranslation } from "react-i18next";
import SearchBar from "@/common/components/searchbar/Searchbar";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Switch from "@/common/components/switch";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import { useEffect, useState } from "react";
import { deleteProduct } from "@/redux/reducers/productLibrary/slices/deleteProduct";
import { updateProduct } from "@/redux/reducers/productLibrary/slices/listProducts";
import { ReactComponent as Spinner } from "@/common/assets/svg/spinner.svg";
import { capitalize } from "@/utils/capitalize";
import { ProductStatus } from "@/types/product-library";
import { TableCellsIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import EmptyState from "@/common/components/empty-state/EmptyState";
import PageHeader from "@/common/components/page-header/PageHeader";

function ProductList({
  filters,
  setFilters,
}: {
  filters: { status?: ProductStatus; name?: string };
  setFilters: (filters: { status?: ProductStatus; name?: string }) => void;
}) {
  const productList = useAppSelector(({ productLibrary }) => productLibrary.listProducts.products);
  const productListLoading = useAppSelector(({ productLibrary }) => productLibrary.listProducts.loading);

  const dispatch = useAppDispatch();
  const { deleteConfirm } = useConfirmModal();
  const theme = useTheme();

  useEffect(() => {
    if (productList === undefined && !productListLoading) {
      dispatch(getProducts(filters));
    }
  }, [dispatch, filters, productList, productListLoading]);

  if (productList === undefined || productListLoading) {
    return <Spinner style={{ textAlign: "center", width: "100%" }} />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: 1,
          gap: 2,
        }}
      >
        {!productList?.length && (
          <EmptyState
            Icon={TableCellsIcon}
            title="No products added yet!"
            description={
              <span>
                Start adding line items for the products you're <br /> selling to your prospects.
              </span>
            }
          />
        )}
        {productList?.map((product) => (
          <Box
            key={product.name}
            sx={{
              backgroundColor: theme.palette.common.white,
              border: "1px solid #E7EBED",
              flexDirection: "column",
              borderRadius: 2,
              display: "flex",
              gap: 1,
              p: 2,
            }}
          >
            <Box sx={{ borderRadius: 1, py: 1, px: 2, backgroundColor: theme.palette.midnight.p }}>
              <Typography color="white">{product.name}</Typography>
            </Box>
            <Box sx={{ p: 2, borderRadius: 1, borderBottom: `1px solid ${theme.palette.grey[200]}` }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ flex: 1 }}>
                  <Typography fontWeight={500}>Unit Price</Typography>
                  <Typography fontWeight={400}>${product.price}</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography fontWeight={500}>Billing Frequency</Typography>
                  {/* TODO no value */}
                </Box>
                {/* <Box sx={{ flex: 1 }}>
                  <Typography fontWeight={500}>Billing Term(Months)</Typography>
                  <Typography fontWeight={400}>1</Typography>
                </Box> */}
                <Box sx={{ flex: 1 }}>
                  <Typography fontWeight={500}>Product Status</Typography>
                  <Switch
                    value={product.status === "ACTIVE"}
                    onChange={(e) => {
                      dispatch(updateProduct({ ...product, status: e?.target.checked ? "ACTIVE" : "INACTIVE" }));
                    }}
                    label={capitalize(product.status)}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <ButtonBase
                onClick={() => dispatch(openCreateProductDrawer({ product }))}
                sx={{ py: 1, px: 1.25, border: `2px solid ${theme.palette.btnSecondary.default}`, borderRadius: 2 }}
              >
                <PencilSquareIcon width={16} />
              </ButtonBase>
              <ButtonBase
                onClick={() =>
                  deleteConfirm({})
                    .then(() => dispatch(deleteProduct(product.id)))
                    .catch(() => {
                      return;
                    })
                }
                sx={{ py: 1, px: 1.25, border: `2px solid ${theme.palette.border.danger}`, borderRadius: 2 }}
              >
                <TrashIcon width={16} color={theme.palette.commonColors.danger} />
              </ButtonBase>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default function () {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [filters, setFilters] = useState<{ status?: ProductStatus; name?: string }>({});
  const productList = useAppSelector(({ productLibrary }) => productLibrary.listProducts.products);
  const { t } = useTranslation();
  const { pendingCreateProduct, pendingUpdateProduct } = useAppSelector(
    ({ productLibrary }) => productLibrary.listProducts,
  );

  useEffect(() => {
    if (productList === undefined) {
      dispatch(getProducts());
    }
  }, [dispatch, productList]);

  return (
    <main>
      <FullLoadingOverlay loading={pendingCreateProduct || pendingUpdateProduct} overlay={true} />
      <PageHeader
        title={t("dealFlow.productLibrary.title")}
        actions={
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button size="sm" variant="secondary">
              Import Product
            </Button>
            <Button size="sm" variant="primary" onClick={() => dispatch(openCreateProductDrawer({}))}>
              Create Product
            </Button>
          </Box>
        }
        filters={
          <Box
            sx={{
              display: "grid",
              gap: "16px",
              gridTemplateColumns: "4fr 1fr",
              marginBottom: "16px",
            }}
          >
            <SearchBar
              sx={{ width: "100%", maxWidth: theme.breakpoints.values.sm }}
              onChange={(e) => setFilters((curr) => ({ ...curr, name: e.target.value }))}
              placeholder="Search for product name"
            />
            <Dropdown
              placeholder="Status"
              label="Status"
              options={[
                { label: "Active", value: "ACTIVE" },
                { label: "Inactive", value: "INACTIVE" },
              ]}
              onChange={(option) => setFilters((curr) => ({ ...curr, status: option.value }))}
            />
          </Box>
        }
      />

      <ProductList filters={filters} setFilters={setFilters} />
      <CreateDrawer />
    </main>
  );
}
