import Box from "@mui/material/Box";
import InboxList from "./list/InboxList";
import ViewInboxMessageDrawer from "./view/ViewInboxMessagesDrawer";
import { selectInboxMessages } from "@/redux/selectors";
import { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { pageMessageThreads } from "@/redux/reducers/inbox/slices/listMessages";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { lookupProspectsInbox } from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Message } from "@/types/inbox";
import PageHeader from "@/common/components/page-header/PageHeader";
import SendNewMessage from "@/pages/inbox/send-new/SendNewMessage";
import InboxFilters from "@/pages/inbox/filters";
import { useTranslation } from "react-i18next";
import InboxListPagination from "@/pages/inbox/list/InboxListPagination";
import { InboxFiltersInput } from "@/pages/inbox/filters/InboxFilters";
import { debounce } from "lodash";

export default function InboxPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [filters, setFilters] = useState<InboxFiltersInput>({ text: "", updatedAtS: null, updatedAtE: null });
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const messages = useAppSelector(selectInboxMessages);
  const messagesLoading = useAppSelector(({ inbox }) => inbox.getInboxData.messagesLoading);
  const threadCount = useAppSelector(({ inbox }) => inbox.getInboxData.threadCount) || 0;

  useEffect(() => {
    dispatch(pageMessageThreads({ filters: { text: "", updatedAtS: null, updatedAtE: null }, page, pageSize }));
  }, [dispatch]);

  const debouncedLookup = useMemo(
    () => debounce((f: InboxFiltersInput) => dispatch(pageMessageThreads({ filters: f, page, pageSize })), 500),
    [dispatch, page, pageSize],
  );

  const handleSetFilters = useCallback(
    (filters: InboxFiltersInput) => {
      console.log("handleSetFilters");
      console.log(filters);
      setFilters(filters);
      debouncedLookup(filters);
    },
    [debouncedLookup],
  );

  const prospectIds: string[] = useMemo(() => {
    if (messages) {
      return messages.map((m: Message) => m.prospect_id);
    }
    return [];
  }, [messages]);

  useEffect(() => {
    if (prospectIds?.length) {
      dispatch(lookupProspectsInbox(prospectIds));
    }
  }, [dispatch, prospectIds]);

  const handlePageSize = (pageSize: number) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const handleClickPrev = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleClickNext = () => {
    if ((page + 1) * pageSize < threadCount) {
      setPage(page + 1);
    }
  };

  if (messagesLoading) return <LoadingSpinner />;

  return (
    <>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        })}
      >
        <PageHeader
          title={t("inbox.title")}
          actions={<SendNewMessage />}
          filters={<InboxFilters filters={filters} setFilters={handleSetFilters} />}
        />
        <InboxList />
        <InboxListPagination
          page={page}
          pageSize={pageSize}
          onSelectPageSize={handlePageSize}
          onClickPrev={handleClickPrev}
          onClickNext={handleClickNext}
        />
      </Box>
      <ViewInboxMessageDrawer />
    </>
  );
}
