import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTeamAiVoiceSettings } from "./get";
import {
  AiVoiceTeamSettings,
  AiVoiceTransferRequest,
  AiVoiceTwilioRequest,
  KnTeamUserRole,
} from "@/services/generated";
import { AxiosResponse } from "axios";
import Services from "@/services";
import { toast } from "react-toastify";

interface AiVoiceSettings {
  twilioAccountSid?: string;
  transferPhoneNumberDefault?: string;
  transferPhoneNumberSales?: string;
  transferPhoneNumberSupport?: string;
  transferPhoneNumberBilling?: string;
}

type State = {
  active: AiVoiceSettings | null;
  draft: AiVoiceSettings | null;
  loading: boolean;
  updating: boolean;
  error: string | null;
  users: KnTeamUserRole[];
  joinError: string | null;
  canSave: boolean;
};

const initialState: State = {
  active: null,
  draft: null,
  loading: false,
  updating: false,
  error: null,
  users: [],
  joinError: null,
  canSave: true,
};

export const updateTeamAiVoice = createAsyncThunk(
  "settings/updateTeamAiVoice",
  async (_, { getState, rejectWithValue, dispatch }): Promise<AxiosResponse<AiVoiceTeamSettings>> => {
    const s = getState() as any;
    const state = s.settings.teamAiVoice.draft;

    try {
      const request: AiVoiceTransferRequest = {
        transfer_phone_number_default: state.transferPhoneNumberDefault,
        transfer_phone_number_sales: state.transferPhoneNumberSales,
        transfer_phone_number_support: state.transferPhoneNumberSupport,
        transfer_phone_number_billing: state.transferPhoneNumberBilling,
      };
      return await Services.AiVoice.upsertAiVoiceTeamSettings(request);
    } catch (error: any) {
      return error;
    }
  },
);

export const updateTeamAiVoiceTwilio = createAsyncThunk(
  "settings/updateTeamAiVoice",
  async (request: AiVoiceTwilioRequest, { rejectWithValue, dispatch }): Promise<AxiosResponse<AiVoiceTeamSettings>> => {
    try {
      const response = await Services.AiVoice.upsertAiVoiceTwilio(request);
      toast.success("Twilio authorized successfully");
      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const deauthorizeAiVoiceTwilio = createAsyncThunk(
  "oauth/deauthorizeAiVoiceTwilio",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Services.AiVoice.deauthorizeAiVoiceTwilio();
      toast.success("Twilio deauthorized successfully");
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const teamAiVoiceSlice = createSlice({
  name: "teamAiVoice",
  initialState,
  reducers: {
    set: (state, action) => {
      state.active = {
        ...action.payload,
      };
    },
    setDraftAiVoice: (state, { payload }) => {
      state.draft = {
        ...payload,
      };
    },
    setDraftTwilio: (state, { payload }) => {
      state.draft = {
        ...payload,
      };
    },
    setCanSaveAiVoice: (state, { payload }) => {
      state.canSave = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamAiVoiceSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTeamAiVoiceSettings.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.active = {
            twilioAccountSid: payload.data.twilio_account_sid,
            transferPhoneNumberDefault: payload.data.transfer_phone_number_default,
            transferPhoneNumberSales: payload.data.transfer_phone_number_sales,
            transferPhoneNumberSupport: payload.data.transfer_phone_number_support,
            transferPhoneNumberBilling: payload.data.transfer_phone_number_billing,
          };
        }
        state.loading = false;
      })
      .addCase(updateTeamAiVoice.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateTeamAiVoice.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.active = {
            twilioAccountSid: payload.data.twilio_account_sid,
            transferPhoneNumberDefault: payload.data.transfer_phone_number_default,
            transferPhoneNumberSales: payload.data.transfer_phone_number_sales,
            transferPhoneNumberSupport: payload.data.transfer_phone_number_support,
            transferPhoneNumberBilling: payload.data.transfer_phone_number_billing,
          };
        }
        state.updating = false;
      })
      .addCase(deauthorizeAiVoiceTwilio.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.active = {
            ...state.active,
            twilioAccountSid: payload.data.twilio_account_sid,
            // twilioAuthToken: null,
          };
        }
        state.loading = false;
      });
  },
});

export const { set, setDraftAiVoice, setCanSaveAiVoice } = teamAiVoiceSlice.actions;

export default teamAiVoiceSlice.reducer;
