import { Message } from "@/types/inbox";
import { Box } from "@mui/material";
import { useMemo } from "react";
import { useAppSelector } from "@/redux/store";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import { PaperClipOutline, UserCircle } from "heroicons-react";
import Pill from "@/common/components/pill";
import Avatar from "@/common/components/avatar";
import dayjs from "dayjs";
import config from "@/common/constants/config";

const NameAndIcons = ({ prospect, subject }: { prospect?: Prospect; subject: string | undefined }) => {
  // const dispatch = useAppDispatch();

  // console.log("prospects", prospects);
  // console.log("to_user_id", to_user_id);

  // useEffect(() => {
  //   dispatch(getProspects({}));
  // }, [dispatch]);

  // if (!prospects) return <FullLoadingOverlay />;

  const prospectName = `${prospect?.firstName} ${prospect?.lastName}`;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "2px",
          alignItems: "center",
        }}
      >
        <UserCircle size={18} />
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.2px",
            color: "#1A2135",
          }}
        >
          {prospect ? prospectName : "Unknown"}
        </Box>

        {subject && (
          <Box
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "20px",
              letterSpacing: "0.2px",
              color: "#1A2135",
            }}
          >
            - {subject}
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex", gap: "4px", mb: 1 }}>
        {config.flagOutreach && (
          <>
            <Pill bgColor="disabled" iconType="envelope" rounded={false} />
            <Pill bgColor="disabled" iconType="message" rounded={false} />
            <Pill bgColor="disabled" iconType="message" rounded={false} />
          </>
        )}
        <Avatar size="small" label={prospect ? prospectName : "Unknown"} />
      </Box>
    </Box>
  );
};

const timeLabel = (date: string) => {
  return dayjs(date).format("hh:mm A");
};

const removeHtmlTags = (str: string) => {
  return str.replace(/<[^>]*>?/gm, "");
};

export const BodyAndTime = ({
  body,
  createdAt,
  hasAttachment,
}: {
  body: string;
  createdAt: string;
  hasAttachment: boolean;
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <Box sx={{ display: "flex", gap: "2px" }}>
      <Box
        sx={{
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          letterSpacing: "0.2px",
          color: "#6B747B",
        }}
      >
        {body.length > 30 ? removeHtmlTags(body).slice(0, 100) + "..." : removeHtmlTags(body)}
      </Box>
      {hasAttachment && (
        <PaperClipOutline
          style={{
            width: "16px",
            height: "16px",
            stroke: "#374667",
          }}
        />
      )}
    </Box>

    <Box
      sx={{
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "0.2px",
      }}
    >
      {timeLabel(createdAt)}
    </Box>
  </Box>
);

type InboxRowProps = {
  message: Message;
  onClick: () => void;
};

export const InboxRow = (props: InboxRowProps) => {
  const message = props.message;
  const onClick = props.onClick;

  const { prospectsInbox } = useAppSelector(({ prospects }) => prospects.listProspects);

  const prospectId = message.prospect_id;
  const prospect = useMemo(() => {
    return prospectsInbox?.find((prospect) => prospect.id === prospectId);
  }, [prospectsInbox, prospectId]);

  return (
    <Box
      key={message.id}
      sx={{
        color: "##374667",
        background: "#FFFFFF",
        borderRadius: "8px",
        marginBottom: "4px",
        padding: "16px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <NameAndIcons prospect={prospect} subject={message?.subject} />
        <BodyAndTime
          body={message.body}
          createdAt={message.createdAt || ""}
          hasAttachment={!!message?.attachment?.length}
        />
      </Box>
    </Box>
  );
};
