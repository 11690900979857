import { Box } from "@mui/material";
import Button from "@/common/components/button";
import { useNavigate } from "react-router";
import { getCoreRowModel, useReactTable, getPaginationRowModel } from "@tanstack/react-table";
import { SequenceColumns } from "./table/columns";

import SequenceFilters from "./filters";
import SequenceTable from "./table";
import { SequenceTemplate, SequenceTemplateStatusType } from "@/types/sequence";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  getSequencesTemplates,
  setCurrentSequence,
  setSequenceFilters,
} from "@/redux/reducers/sequences/slices/listSequences";
import routes from "@/common/constants/routes";
import PageHeader from "@/common/components/page-header/PageHeader";

export type SequenceFiltersInput = {
  name?: string;
  status?: SequenceTemplateStatusType[];
};

export default function SequenceBuilderPage() {
  const sequencesLoading = useAppSelector((selector) => selector.sequences.listSequences.loading);
  const sequences = useAppSelector((selector) => selector.sequences.listSequences.sequences);
  const [filters, setFilters] = useState<SequenceFiltersInput>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const table = useReactTable<SequenceTemplate>({
    data: sequences || [],
    columns: SequenceColumns,
    pageCount: sequences?.length,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    dispatch(setSequenceFilters({ ...filters }));
    dispatch(getSequencesTemplates());
  }, [dispatch, filters]);

  const handleNavigate = () => {
    dispatch(setCurrentSequence({ sequence: null }));
    navigate(routes.sequence.create.path);
  };

  useEffect(() => {
    if (sequences === undefined && !sequencesLoading) {
      dispatch(getSequencesTemplates());
    }
  }, [dispatch, sequencesLoading, sequences]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <PageHeader
        title="Sequences"
        actions={
          <Button variant="primary" onClick={handleNavigate}>
            Create Sequence
          </Button>
        }
        filters={<SequenceFilters filters={filters} setFilters={setFilters} />}
      />

      <SequenceTable table={table} filters={filters} />
    </Box>
  );
}
