import Box from "@mui/material/Box";

import { useForm } from "react-hook-form";
import { useAppDispatch } from "@/redux/store";

import { useEffect, useMemo } from "react";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { KnCompanyImportJobImportJobTypeEnum } from "@/services/generated";
import { TaskTypeSelectOptions } from "@/redux/reducers/tasks/slices/helpers";
import { DataImportFiltersInput } from "@/pages/data-import";
import { debounce } from "lodash";

type FilterOption = {
  label: string;
  value: KnCompanyImportJobImportJobTypeEnum;
};

export const ImportObjectTypeOptions = [
  { label: "Contact", value: KnCompanyImportJobImportJobTypeEnum.Contact },
  { label: "Company", value: KnCompanyImportJobImportJobTypeEnum.Company },
  { label: "Product", value: KnCompanyImportJobImportJobTypeEnum.Product },
];

export type DataImportFiltersProps = {
  filters: DataImportFiltersInput;
  setFilters: (
    filters: DataImportFiltersInput | ((prevFilters: DataImportFiltersInput) => DataImportFiltersInput),
  ) => void;
};

export default function DataImportFilters({ filters, setFilters }: DataImportFiltersProps) {
  const { control, watch } = useForm({});
  const watchedFields = watch();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Object.values(watchedFields).every((field) => field === undefined)) return;
    // handleFilterSubmit(watchedFields);
  }, [watchedFields]);

  const debouncedSetFilters = useMemo(
    () =>
      debounce((value: string) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          search: value,
        }));
      }, 500),
    [setFilters],
  );

  const handleChange = (option: FilterOption[]) => {
    if (option.length > 0) {
      setFilters((previousFilters) => ({
        ...previousFilters,
        objectType: option.map((option: FilterOption) => option.value),
      }));
    } else {
      setFilters((previousFilters) => ({ ...previousFilters, objectType: undefined }));
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Box>
        <Searchbar placeholder="Search by Name" />
      </Box>
      <Box />
      <Box sx={{ minWidth: 200 }}>
        <Dropdown
          label="Object Type"
          options={ImportObjectTypeOptions}
          placeholder="Object Type"
          isMulti
          value={filters.objectType as any}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ minWidth: 200 }}>
        <Dropdown
          label="Task Type"
          placeholder="Task Type"
          options={TaskTypeSelectOptions}
          onChange={() => {
            // TODO: Filter implementation, import date range
          }}
        />
      </Box>
    </Box>
  );
}
