import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import { useEffect, useMemo, useState } from "react";
import DrawerLayout from "@/layouts/DrawerLayout";
import { useForm, Controller, FormProvider, SubmitHandler } from "react-hook-form";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import { StepTemplate } from "@/types/sequence";
import * as Yup from "yup";
import { getTeamUsers } from "@/redux/reducers/settings/team/get";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { genMsgCustom, genMsgSuggestMeetingTimes } from "@/redux/reducers/inbox/slices/generateMessage";
import { GenMsgCustomMessageTypeEnum } from "@/services/generated";
import AiForm from "@/pages/inbox/AiForm";
import Dropdown from "@/common/components/dropdown";
import { TextEditor } from "@/common/components/text-editor/TextEditor";
import { ActionButtons } from "@/pages/inbox/tabs/ActionButtons";
import { getConsentsForCurrentUser } from "@/redux/reducers/consent/slices/getConsentsForCurrentUser";
import { useUploadPercents } from "@/common/components/drag-and-drop/useUploadPercent";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitErrorHandler } from "react-hook-form/dist/types/form";
import { SmsFormType } from "@/pages/inbox/tabs/SmsTab";

interface CreateMessageProps {
  closeDrawer: () => void;
  isNew?: boolean;
  isOpen: boolean;
  onAddAction: (data: any) => void;
  messageType: StepTemplate["actionType"];
  content?: string;
  subject?: string;
}

// TODO: Require
const schema = Yup.object({
  from: Yup.string().label("From").required(),
  subject: Yup.string().label("Subject").required(),
  message: Yup.string().label("Message").required(),
  // attachments: Yup.array().of(
  //   Yup.object().shape({
  //     media: Yup.mixed().required(),
  //     name: Yup.string().required(),
  //   }),
  // ),
  type: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  userIds: Yup.array().of(Yup.string().required()),
  prompt: Yup.string().label("Prompt"),
});

export type Form = Yup.InferType<typeof schema>;

export default function DraftTemplateEmail({
  closeDrawer,
  isOpen,
  onAddAction,
  messageType,
  content,
  subject,
  isNew,
}: CreateMessageProps) {
  const { percents, handleDropInterval, addFile, isUploading } = useUploadPercents(); // Inicializa el hook
  const dispatch = useAppDispatch();
  const consents = useAppSelector((state) => state.consent.consents);
  const users = useAppSelector(({ settings }) => settings.team.users);
  const [isShowGenAi, setIsShowGenAi] = useState(false);
  const methods = useForm<Form>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!consents?.length) {
      dispatch(getConsentsForCurrentUser());
    }
  }, [consents, dispatch]);
  const { control, reset, getValues, setValue } = methods;

  useEffect(() => {
    if (!users?.length) {
      dispatch(getTeamUsers());
    }
  }, [dispatch]);

  const usersWithCal = useMemo(() => {
    return (users || []).filter((u) => u.calendarConnected);
  }, [users]);

  const generateMessage = (e: Event) => {
    e.preventDefault();

    const values = getValues();
    console.log("Generating message", values);
    const promise =
      values.type.value === "suggest_meeting_times"
        ? dispatch(
            genMsgSuggestMeetingTimes({
              message_type: GenMsgCustomMessageTypeEnum.Email,
              // contact_id: ,
              meet_with_user_id_list: values.userIds || [],
            }),
          )
        : dispatch(
            genMsgCustom({
              message_type: GenMsgCustomMessageTypeEnum.Email,
              // contact_id: ,
              // TODO remove this with conditional required
              prompt: values.prompt || "",
            }),
          );

    promise.then((response) => {
      console.log("Response", response);
      setValue("message", response.payload.data.message);
      setIsShowGenAi(false);
    });
  };

  const onSubmit: SubmitHandler<SmsFormType> = (data) => {
    onAddAction(getValues());
  };

  const onError: SubmitErrorHandler<Yup.InferType<typeof schema>> = (errors) => {
    console.log(errors);
  };

  const EmailComposer = () => (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <FormControl sx={{ flexGrow: 1, width: "100%", borderRadius: "0px" }}>
        <Controller
          name="from"
          control={control}
          render={({ field }) => {
            return (
              <div style={{ zIndex: 2 }}>
                <FormLabel label="Sender Email" aria-required />
                <Dropdown
                  {...field}
                  options={consents?.map((consent: any) => ({
                    value: consent?.emailAddress,
                    label: consent?.emailAddress,
                  }))}
                  onChange={(option) => field.onChange(option.value)}
                  value={field.value}
                />
              </div>
            );
          }}
        />
      </FormControl>
      <Box>
        <FormControl sx={{ flexGrow: 1, width: "100%" }}>
          <Controller
            name="subject"
            control={control}
            render={({ field }) => {
              return (
                <div style={{ position: "relative" }}>
                  <FormLabel
                    label="Subject"
                    sx={{
                      position: "absolute",
                      bottom: 4,
                      left: "10px",
                      zIndex: 1,
                    }}
                  />
                  <Input {...field} hasRoundedBottomBorders={false} leftPadding={50} />
                </div>
              );
            }}
          />
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <Controller
            name="message"
            control={control}
            render={({ field }) => {
              return <TextEditor {...field} handleDropInterval={handleDropInterval} addFile={addFile} />;
            }}
          />
        </FormControl>
      </Box>
      {/*{attachments && attachments?.length ? (*/}
      {/*  <div>*/}
      {/*    <FormLabel label="Attachments" />*/}
      {/*    <div>*/}
      {/*      {attachments?.map((file: any, index: number) => (*/}
      {/*        <div*/}
      {/*          key={index}*/}
      {/*          style={{*/}
      {/*            padding: "16px",*/}
      {/*            background: "#F2F4F8",*/}
      {/*            borderRadius: "8px",*/}
      {/*            display: "flex",*/}
      {/*            justifyContent: "space-between",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <div style={{ display: "flex", gap: "8px" }}>*/}
      {/*            <span*/}
      {/*              style={{*/}
      {/*                color: percents[index] === 100 ? "#2C72E2" : "#000",*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              {file.name}*/}
      {/*            </span>*/}
      {/*            {percents[index] === 100 ? <span>({bytesToKB(file.media.size)}kb)</span> : null}*/}
      {/*          </div>*/}
      {/*          <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>*/}
      {/*            {isUploading && percents[index] < 100 ? <ProgressBar percent={percents[index]} /> : null}*/}
      {/*            <XMarkIcon*/}
      {/*              style={{ cursor: "pointer", width: "24px", height: "24px" }}*/}
      {/*              onClick={() =>*/}
      {/*                setValue(*/}
      {/*                  "attachments",*/}
      {/*                  attachments.filter((attachment: any, attachmentIndex: number) => attachmentIndex !== index),*/}
      {/*                )*/}
      {/*              }*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      ))}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*) : null}*/}

      {/* TODO handleAddAction */}
      <ActionButtons sendText="Send Email" onSubmit={onSubmit} onError={onError} setIsGenerating={setIsShowGenAi} />
    </div>
  );

  return (
    <Drawer open={isOpen} onClose={closeDrawer} anchor="right">
      <DrawerLayout title="Messages" closeDrawer={closeDrawer}>
        <FormProvider {...methods}>
          <form>
            {isShowGenAi ? (
              <AiForm
                users={usersWithCal}
                onClose={() => setIsShowGenAi(false)}
                onGenerate={(e) => generateMessage(e)}
              />
            ) : (
              <>
                <EmailComposer />
              </>
            )}
          </form>
        </FormProvider>
      </DrawerLayout>
    </Drawer>
  );
}
