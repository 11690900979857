import { Typography } from "@mui/material";
import { useAppDispatch } from "@/redux/store";
import { createColumnHelper, getCoreRowModel, useReactTable, getPaginationRowModel } from "@tanstack/react-table";

import { openCreateDealDrawer } from "@/redux/reducers/dealFlow/slices/listDeals";
import { DealCard } from "@/redux/reducers/dealFlow/slices/types";
import HeatScore from "@/common/components/heat-score/HeatScore";
import TsTable from "@/common/components/ts-table";
import IndeterminateCheckbox from "@/common/components/checkbox/IntermediateCheckbox";
import dayjs from "dayjs";
import { TableCellsIcon } from "@heroicons/react/24/outline";

const columnHelper = createColumnHelper<DealCard>();

const columns = [
  columnHelper.accessor("name", {
    header: ({ table }) => (
      <div style={{ display: "flex", gap: "18px", alignItems: "center", marginLeft: "16px" }}>
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
        <Typography variant="body3">DEAL NAME</Typography>
      </div>
    ),

    cell: ({ row }) => {
      return (
        <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "16px" }}>
          <IndeterminateCheckbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
          <Typography variant="body2">{row.original.name}</Typography>
        </div>
      );
    },
  }),

  columnHelper.accessor("price", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Value $
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">${info.getValue().toFixed(2)}</Typography>;
    },
  }),

  columnHelper.accessor("assignee", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Prospect
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()?.name}</Typography>;
    },
  }),

  columnHelper.accessor("assignee", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Assignee
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()?.name}</Typography>;
    },
  }),

  columnHelper.accessor("stage", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Stage
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()?.name}</Typography>;
    },
  }),

  columnHelper.accessor("heatScore", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Heat Score
      </Typography>
    ),
    cell: (info) => {
      return <HeatScore score={info.getValue()} />;
    },
  }),

  columnHelper.accessor("updatedAt", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Last Activity
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{dayjs(info.getValue()).format("MMM DD, YYYY")}</Typography>;
    },
  }),
];

export default function Table({ cards }: { cards: DealCard[] }) {
  const dispatch = useAppDispatch();

  const handleCellClick = (deal?: DealCard) => {
    dispatch(openCreateDealDrawer({ deal }));
  };

  const table = useReactTable({
    data: cards || [],
    columns,
    pageCount: cards.length,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <TsTable
      table={table}
      handleCellClick={(_, data) => {
        handleCellClick(data);
      }}
      notFoundProps={{
        title: "No deals found",
        description: "Begin creating an agent by clicking the Create New Deal button above.",
        Icon: TableCellsIcon,
      }}
    />
  );
}
