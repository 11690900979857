import DataImportTable from "./table";
import { Box } from "@mui/material";
import DataImportFilters from "./filters";
import Button from "@/common/components/button";
import { useNavigate } from "react-router-dom";
import { getImportData } from "@/redux/reducers/dataImport/slices/list";
import routes from "@/common/constants/routes";
import PageHeader from "@/common/components/page-header/PageHeader";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect, useState } from "react";
import { KnCompanyImportJobImportJobTypeEnum } from "@/services/generated";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import { FormProvider, useForm } from "react-hook-form";

export type DataImportFiltersInput = {
  search?: string;
  objectType?: KnCompanyImportJobImportJobTypeEnum[];
  sortBy?: string;
  sortDirection?: string;
};

export default function DataImport() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<DataImportFiltersInput>({});
  const data = useAppSelector((selector) => selector.dataImport.list.data);
  const loading = useAppSelector((selector) => selector.dataImport.list.loading);

  const methods = useForm({});

  useEffect(() => {
    dispatch(getImportData(filters));
  }, [dispatch, filters]);

  const handleGoToNewImport = () => {
    navigate(routes.dataImport.import.path);
  };

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <PageHeader
          title="Data Import"
          actions={
            <Button data-testid="openTaskDrawer" variant="primary" onClick={handleGoToNewImport}>
              New Import
            </Button>
          }
          filters={<DataImportFilters filters={filters} setFilters={setFilters} />}
        />

        {loading ? <LoadingSpinner /> : <DataImportTable filters={filters} setFilters={setFilters} />}
      </Box>
    </FormProvider>
  );
}
