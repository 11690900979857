import { Box } from "@mui/material";
import TestCall from "@/pages/ai-voice/ai-agents/test-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { lookupAgents } from "@/redux/reducers/ai-voice/listAgents";
import AiAgentFilters from "@/pages/ai-voice/ai-agents/filters";
import Button from "@/common/components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { AiAgent, AiVoiceCallDirection, AiVoiceCallFlowType } from "@/types/ai-voice";
import AIAgentCard from "../ai-agent-card";
import _, { debounce } from "lodash";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import routes from "@/common/constants/routes";
import PageHeader from "@/common/components/page-header/PageHeader";
import EmptyState from "@/common/components/empty-state/EmptyState";
import { PhoneIcon } from "@heroicons/react/24/outline";

export type AiAgentFiltersInput = {
  text?: string;
  callDirection?: AiVoiceCallDirection;
  callFlow?: AiVoiceCallFlowType;
};

export default function AiAgentsPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [filters, setFilters] = useState<AiAgentFiltersInput>({ callDirection: undefined, callFlow: undefined });

  const agentsList = useAppSelector(({ aiVoice }) => aiVoice.listAgents.data);
  const agentsIsLoading = useAppSelector(({ aiVoice }) => aiVoice.listAgents.loading);

  // const debouncedFilters = _.debounce(() => filters, 2000);
  // const debounceLookupAiAgents = _.debounce((filters: AiAgentFiltersInput) => {
  //   dispatch(lookupAgents(filters));
  // }, 3000);

  const [agent, setAgent] = useState<AiAgent | null>(null);

  useEffect(() => {
    if (agentsList === undefined) {
      dispatch(lookupAgents({}));
    }
  }, [dispatch, agentsList]);

  useEffect(() => {
    if (!_.isEmpty(filters)) {
      setFilters({});
    }
  }, [location]);

  const debouncedLookup = useMemo(
    () => debounce((f: AiAgentFiltersInput) => dispatch(lookupAgents(f)), 1000),
    [dispatch],
  );

  const handleSetFilters = useCallback(
    (filters: AiAgentFiltersInput) => {
      setFilters(filters);
      debouncedLookup(filters);
    },
    [debouncedLookup],
  );

  const openAiAgentCreate = () => {
    navigate(routes.aiVoice.agents.create.path);
  };

  return (
    <>
      <FullLoadingOverlay loading={agentsIsLoading} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <PageHeader
          title="Agents"
          actions={
            <Button data-testid="openTaskDrawer" variant="primary" onClick={openAiAgentCreate}>
              Create Agent
            </Button>
          }
          filters={<AiAgentFilters filters={filters} setFilters={handleSetFilters} />}
        />

        {agentsList?.length === 0 && (
          <EmptyState
            Icon={PhoneIcon}
            title="No agents created yet"
            description={
              <span>
                Begin creating an agent by clicking the <br />
                <b>"Create Agent" </b>
                button above.
              </span>
            }
          />
        )}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 2,
          }}
        >
          {(agentsList || []).map((aiAgent) => {
            return (
              <AIAgentCard
                key={aiAgent.agent_id}
                aiAgent={aiAgent}
                showCallTest={aiAgent.call_direction === "OUTBOUND"}
                openCallTest={() => setAgent(aiAgent)}
              />
            );
          })}
          {agent && <TestCall open={!!agent} agent={agent} onClose={() => setAgent(null)} />}
        </Box>
      </Box>
    </>
  );
}
