import Services from "@/services";
import { format } from "date-fns";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  KnFilter2,
  KnLookupRequest2SortDirectionEnum,
  KnLookupResponseTaskLine,
  TaskRequest,
} from "@/services/generated";
import { TaskRequestStatusEnum, TaskRequestTypeEnum } from "@/services/generated";
import { createTask } from "./createTask";
import { updateTask } from "./updateTask";
import { success } from "@/lib/toast";

export interface Task extends TaskRequest {
  id?: string;
  assignee?: {
    id?: string;
    name?: string;
  };
  prospect?: {
    id?: string;
    name?: string;
  };
  deal?: any;
  dueTime?: string;
}

//   type: currentTask?.type,
//   status: currentTask?.status,
//   assignee: currentTask?.assignee?.id,
//   description: currentTask?.description,
//   dueDate: currentTask?.dueDate,
//   dueTime: currentTask?.dueDate,
//   deal: currentTask?.deal?.id,
//   prospect: currentTask?.prospect?.id,
//   attachments: currentTask?.attachments || [],

type State = {
  viewDrawerIsOpen: boolean;
  smsEmailDrawerIsOpen: boolean;
  editDrawerIsOpen: boolean;
  totalCount: number;
  pageNumber: number;
  pageSize: number;
  assigneeDrawerIsOpen?: boolean;

  // Get current task
  currentTask: Task | null;
  loadingCurrentTask?: boolean;
  currentTaskId?: string;

  // Get tasks
  tasks?: Task[];
  loadingTasks?: boolean;

  // Update task
  pendingUpdateTask?: boolean;
  errorUpdateTask?: string;

  // Create task
  pendingCreateTask?: boolean;
  createTaskDrawerIsOpen: boolean;
  errorCreateTask?: string;
};

const initialState: State = {
  currentTask: null,
  tasks: [],
  loadingTasks: true,
  viewDrawerIsOpen: false,
  smsEmailDrawerIsOpen: false,
  editDrawerIsOpen: false,
  totalCount: 0,
  pageNumber: 0,
  pageSize: 10,
  assigneeDrawerIsOpen: false,
  pendingCreateTask: false,
  pendingUpdateTask: false,
  loadingCurrentTask: false,
  createTaskDrawerIsOpen: false,
};

type Filters = {
  pageNumber?: number;
  pageSize?: number;
  assigneeId?: string;
  type?: TaskRequestTypeEnum;
  status?: TaskRequestStatusEnum;
  dealId?: string;
  dueDateStart?: string;
  dueDateEnd?: string;
  sortBy?: string;
  sortDirection?: string;
  prospectName?: string;
  createTaskDrawerIsOpen?: boolean;
};

export const setCurrentTask = createAsyncThunk("tasks/get", async (id: string) => {
  try {
    const response = await Services.Tasks.get(id);
    return response;
  } catch (error) {
    return error;
  }
});

export const getTasks = createAsyncThunk(
  "tasks/getTasks",
  async (filters?: Filters): Promise<KnLookupResponseTaskLine> => {
    try {
      const filtersArray: KnFilter2[] = [];

      if (filters?.assigneeId) {
        filtersArray.push({
          property: "assigneeId",
          options: [
            {
              operator: "equal",
              values: [filters.assigneeId],
            },
          ],
        });
      }

      if (filters?.type?.length) {
        filtersArray.push({
          property: "type",
          options: [
            {
              operator: "equal",
              values: [filters.type],
            },
          ],
        });
      }

      if (filters?.status?.length) {
        filtersArray.push({
          property: "status",
          options: [
            {
              operator: "equal",
              values: [filters.status],
            },
          ],
        });
      }

      if (filters?.dealId?.length) {
        filtersArray.push({
          property: "dealId",
          options: [
            {
              operator: "equal",
              values: [filters.dealId],
            },
          ],
        });
      }

      if (filters?.prospectName) {
        filtersArray.push({
          property: "prospectName",
          options: [
            {
              operator: "equal",
              values: [filters.prospectName],
            },
          ],
        });
      }

      if (filters?.dueDateStart && filters?.dueDateEnd) {
        filtersArray.push({
          property: "dueDate",
          options: [
            {
              operator: "between",
              values: [
                format(new Date(filters.dueDateStart), "yyyy-MM-dd"),
                format(new Date(filters.dueDateEnd), "yyyy-MM-dd"),
              ],
            },
          ],
        });
      }

      const response = await Services.Tasks.findAll({
        page: filters?.pageNumber ?? 0,
        pageSize: filters?.pageSize ?? 10,
        filters: filtersArray,
        sortBy: filters?.sortBy ?? "createdAt",
        sortDirection: (filters?.sortDirection ?? "desc") as KnLookupRequest2SortDirectionEnum,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const deleteTask = createAsyncThunk("tasks/deleteTask", async (id: string) => {
  await Services.Tasks._delete(id);
  return id;
});

const listTaskSlice = createSlice({
  name: "listTask",
  initialState,
  extraReducers: (builder) => {
    builder

      // GET TASKS
      .addCase(getTasks.fulfilled, (state, { payload }) => {
        if (payload?.results) {
          state.loadingTasks = false;
          state.totalCount = payload.total || 0;
          // state.pageNumber = payload.pageNumber || 0;
          // state.pageSize = payload.pageSize || 0;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.tasks = payload.results || [];
        }
      })

      // DELETE TASK
      .addCase(deleteTask.fulfilled, (state, { payload }) => {
        state.tasks = state.tasks?.filter((task) => task.id !== payload);
        success("Task deleted successfully");
      })

      // UPDATE TASK
      .addCase(updateTask.rejected, (state) => {
        state.pendingUpdateTask = false;
        state.errorUpdateTask = "Failed to update task";
      })
      .addCase(updateTask.pending, (state) => {
        state.pendingUpdateTask = true;
      })
      .addCase(updateTask.fulfilled, (state, { payload }) => {
        if (payload) {
          state.tasks = state.tasks?.map((task) => (task.id === payload.id ? payload : task)) as any;
          state.pendingUpdateTask = false;
          state.editDrawerIsOpen = false;
          state.viewDrawerIsOpen = false;
        }
      })

      // CREATE TASK
      .addCase(createTask.pending, (state) => {
        state.pendingCreateTask = true;
      })
      .addCase(createTask.fulfilled, (state, { payload }: any) => {
        if (payload) {
          state.tasks = [payload, ...(state.tasks ?? [])];
          state.pendingCreateTask = false;
          state.createTaskDrawerIsOpen = false;
        }
      })
      .addCase(createTask.rejected, (state) => {
        state.pendingCreateTask = false;
        state.errorCreateTask = "Failed to create task";
      })

      // SET CURRENT TASK
      .addCase(setCurrentTask.pending, (state) => {
        state.loadingCurrentTask = true;
      })
      .addCase(setCurrentTask.fulfilled, (state, action) => {
        if (action.payload) {
          state.loadingCurrentTask = false;
          state.currentTask = action.payload.data;
        }
      });
  },
  reducers: {
    openViewTaskDrawer: (state) => {
      state.viewDrawerIsOpen = true;
    },
    closeViewTaskDrawer: (state) => {
      state.viewDrawerIsOpen = false;
      state.currentTask = null;
    },
    smsEmailViewDrawerToggle: (state) => {
      state.smsEmailDrawerIsOpen = !state.smsEmailDrawerIsOpen;
    },
    openCreateTaskDrawer: (state) => {
      state.createTaskDrawerIsOpen = true;
      state.currentTask = null;
    },
    closeCreateTaskDrawer: (state) => {
      state.createTaskDrawerIsOpen = false;
      state.currentTask = null;
    },
    openEditTaskDrawer: (state) => {
      state.editDrawerIsOpen = true;
    },
    closeEditTaskDrawer: (state) => {
      state.editDrawerIsOpen = false;
      state.currentTask = null;
    },
    openAssigneeDrawer: (state) => {
      state.assigneeDrawerIsOpen = true;
    },
    closeAssigneeDrawer: (state) => {
      state.assigneeDrawerIsOpen = false;
      state.currentTask = null;
    },

    nextTask: (state) => {
      if (!state.tasks) {
        return;
      }
      const { tasks, currentTask } = state;
      const nextTaskPosition = tasks.findIndex((task) => task.id === currentTask?.id) + 1;
      const nextTask = tasks[nextTaskPosition];
      if (nextTaskPosition > tasks.length - 1) return;
      state.currentTask = nextTask;
    },
  },
});

export const {
  nextTask,
  openViewTaskDrawer,
  closeViewTaskDrawer,
  openEditTaskDrawer,
  closeEditTaskDrawer,
  smsEmailViewDrawerToggle,
  openAssigneeDrawer,
  closeAssigneeDrawer,
  openCreateTaskDrawer,
  closeCreateTaskDrawer,
} = listTaskSlice.actions;

export default listTaskSlice.reducer;
