import { useAppSelector } from "@/redux/store";

import Searchbar from "@/common/components/searchbar/Searchbar";
import { getCompaniesCount } from "@/redux/selectors/companies";
import { useState } from "react";
import Button from "@/common/components/button";

type CompanyFiltersProps = {
  onSetFilters?: (filters: { searchString?: string }) => void;
};
export default function CompanyFilters({ onSetFilters }: CompanyFiltersProps) {
  const hasCompanies = useAppSelector(getCompaniesCount);
  const [searchString, setSearchString] = useState<string>("");

  return (
    <>
      {hasCompanies > 0 && (
        <Searchbar
          sx={{ width: "450px" }}
          placeholder="Search for a company"
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
            onSetFilters?.({ searchString: e.target.value });
          }}
        />
      )}
      {hasCompanies === 0 && searchString !== "" && (
        <Button
          variant="secondary"
          onClick={() => {
            setSearchString("");
            onSetFilters?.({ searchString: "" });
          }}
        >
          Reset Search
        </Button>
      )}
    </>
  );
}
