import { createSlice } from "@reduxjs/toolkit";
import { Message, MessageCall } from "@/types/inbox";
import { pageMessageThreads } from "./listMessages";
import { getEmailCurrentInbox, getSmsCurrentInbox, getCallCurrentInbox } from "./getCurrentInbox";
import { createInboxSms } from "./createSms";
import { genMsgCustom, genMsgSuggestMeetingTimes } from "@/redux/reducers/inbox/slices/generateMessage";

type State = {
  messages: Message[];
  messagesLoading: boolean;
  threadCount?: number;

  viewDrawerIsOpen: boolean;
  sendNewMessageDrawerIsOpen: boolean;

  createSmsPending: boolean;
  smsMessages: any;

  emailCurrentInbox: Message[];
  smsCurrentInbox: Message[];
  callCurrentInbox: MessageCall[];

  pendingEmailCurrentInbox: boolean;
  pendingSmsCurrentInbox: boolean;
  pendingCallCurrentInbox: boolean;

  isLoadingGenAi: boolean;
};

const initialState: State = {
  messages: [] as Message[],
  messagesLoading: false,

  viewDrawerIsOpen: false,
  sendNewMessageDrawerIsOpen: false,

  createSmsPending: false,
  smsMessages: [] as any,

  emailCurrentInbox: [],
  smsCurrentInbox: [],
  callCurrentInbox: [],

  pendingEmailCurrentInbox: false,
  pendingSmsCurrentInbox: false,
  pendingCallCurrentInbox: false,

  isLoadingGenAi: false,
};

const getInboxDataSlice = createSlice({
  name: "getInboxDataSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(pageMessageThreads.pending, (state, { payload }) => {
      state.messagesLoading = true;
    });
    builder.addCase(pageMessageThreads.fulfilled, (state, { payload }) => {
      const data = payload.data;
      state.messages = data.results as Message[];
      state.threadCount = data.total;
      state.messagesLoading = false;
    });

    builder.addCase(createInboxSms.pending, (state) => {
      state.createSmsPending = true;
    });

    builder.addCase(createInboxSms.fulfilled, (state, { payload }) => {
      state.createSmsPending = false;
      closeSendNewMessageDrawer();
    });

    builder.addCase(createInboxSms.rejected, (state) => {
      state.createSmsPending = false;
      closeSendNewMessageDrawer();
    });

    builder.addCase(getEmailCurrentInbox.fulfilled, (state, { payload }) => {
      state.emailCurrentInbox = payload;
      state.pendingEmailCurrentInbox = false;
    });

    builder.addCase(getEmailCurrentInbox.rejected, (state) => {
      state.emailCurrentInbox = [];
      state.pendingEmailCurrentInbox = false;
    });

    builder.addCase(getEmailCurrentInbox.pending, (state) => {
      state.emailCurrentInbox = [];
      state.pendingEmailCurrentInbox = true;
    });

    builder.addCase(getSmsCurrentInbox.fulfilled, (state, { payload }) => {
      state.smsCurrentInbox = payload;
      state.pendingSmsCurrentInbox = false;
    });

    builder.addCase(getSmsCurrentInbox.rejected, (state) => {
      state.smsCurrentInbox = [];
      state.pendingSmsCurrentInbox = false;
    });

    builder.addCase(getSmsCurrentInbox.pending, (state) => {
      state.smsCurrentInbox = [];
      state.pendingSmsCurrentInbox = true;
    });

    builder.addCase(getCallCurrentInbox.fulfilled, (state, { payload }) => {
      state.callCurrentInbox = payload;
      state.pendingCallCurrentInbox = false;
    });

    builder.addCase(getCallCurrentInbox.rejected, (state) => {
      state.callCurrentInbox = [];
      state.pendingCallCurrentInbox = false;
    });

    builder.addCase(getCallCurrentInbox.pending, (state) => {
      state.callCurrentInbox = [];
      state.pendingCallCurrentInbox = true;
    });

    builder.addCase(genMsgSuggestMeetingTimes.pending, (state) => {
      state.isLoadingGenAi = true;
    });

    builder.addCase(genMsgSuggestMeetingTimes.fulfilled, (state) => {
      state.isLoadingGenAi = false;
    });

    builder.addCase(genMsgCustom.pending, (state) => {
      state.isLoadingGenAi = true;
    });

    builder.addCase(genMsgCustom.fulfilled, (state) => {
      state.isLoadingGenAi = false;
    });
  },
  reducers: {
    openViewDrawer: (state) => {
      state.viewDrawerIsOpen = true;
    },
    closeViewDrawer: (state) => {
      state.viewDrawerIsOpen = false;
    },
    closeSendNewMessageDrawer: (state) => {
      state.sendNewMessageDrawerIsOpen = false;
    },
    openSendNewMessageDrawer: (state) => {
      state.sendNewMessageDrawerIsOpen = true;
    },
  },
});

export const { openViewDrawer, closeViewDrawer, closeSendNewMessageDrawer, openSendNewMessageDrawer } =
  getInboxDataSlice.actions;

export default getInboxDataSlice.reducer;
