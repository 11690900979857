import { useAppSelector } from "@/redux/store";
import React, { useState } from "react";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { getProspectsCount } from "@/redux/selectors/prospects";
import Button from "@/common/components/button";

type ProspectFiltersProps = {
  onSetFilters?: (filters: { searchString?: string }) => void;
};

export default function ProspectFilters({ onSetFilters }: ProspectFiltersProps) {
  const hasProspect = useAppSelector(getProspectsCount);
  const [searchString, setSearchString] = useState<string>("");

  return (
    <>
      {hasProspect > 0 && (
        <Searchbar
          sx={{ width: "450px" }}
          value={searchString}
          placeholder="Search for a prospect"
          onChange={(e) => {
            const newValue = e.target.value;
            setSearchString(newValue);
            onSetFilters?.({ searchString: newValue });
          }}
        />
      )}
      {hasProspect === 0 && searchString !== "" && (
        <Button
          variant="secondary"
          onClick={() => {
            setSearchString("");
            onSetFilters?.({ searchString: "" });
          }}
        >
          Reset Search
        </Button>
      )}
    </>
  );
}
