import TsTable from "@/common/components/ts-table";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { DataImportColumns } from "./columns";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { DataImportFiltersProps } from "../filters";
import React from "react";
import { hideSeqEnrollDialog, triggerSeqEnrollLeadSourceId } from "@/redux/reducers/dataImport/slices/list";
import { toast } from "react-toastify";
import SeqEnrollDialog from "@/common/components/enroll-in-sequence/SeqEnrollDialog";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";

export default function DataImportTable({ filters, setFilters }: DataImportFiltersProps) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(({ dataImport }) => dataImport.list.data) || [];

  const seqEnrollShowDialog = useAppSelector(({ dataImport }) => dataImport.list.isShowSeqEnrollDialog);
  const seqEnrollLeadSourceIdList = useAppSelector(({ dataImport }) => dataImport.list.seqEnrollLeadSourceIdList);
  const seqEnrollPending = useAppSelector(({ prospects }) => prospects.listProspects.seqEnrollPending);

  const table = useReactTable({
    data,
    columns: DataImportColumns({ filters, setFilters }) as any,
    pageCount: data.length,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleSeqEnrollClose = () => {
    dispatch(hideSeqEnrollDialog());
  };

  /**
   * Callback for Dialog submit.
   * @param sequenceId
   */
  const handleSeqEnroll = (sequenceId: string) => {
    const leadSourceIdList = seqEnrollLeadSourceIdList;
    if (leadSourceIdList.length === 0) {
      return;
    }
    dispatch(
      triggerSeqEnrollLeadSourceId({
        sequenceTemplateId: sequenceId,
        leadSourceId: leadSourceIdList[0],
      }),
    ).then(() => {
      dispatch(hideSeqEnrollDialog());
      toast.success("Data Import was enrolled in a sequence!");
    });
  };

  return (
    <>
      <FullLoadingOverlay loading={seqEnrollPending} overlay={true} />
      <TsTable
        table={table as any}
        notFoundProps={{
          Icon: DocumentArrowDownIcon,
          title: "No imports found",
          description: (
            <span>
              Begin importing prospects, companies, or products <br /> by clicking the <b>"New Import"</b> button above.
            </span>
          ),
        }}
        handleCellClick={() => {
          // Do Something
        }}
      />

      <SeqEnrollDialog isOpen={seqEnrollShowDialog} handleClose={handleSeqEnrollClose} handleSubmit={handleSeqEnroll} />
    </>
  );
}
