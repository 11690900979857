import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Accordion from "../accordion/Accordion";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import React, { useEffect, useMemo } from "react";
import { getSequencesByContact } from "@/redux/reducers/sequences/slices/listSequences";
import { SequenceResponseStatusEnum } from "@/services/generated";
import { getDealsListByContactId } from "@/redux/reducers/deals/slices/listDeals";
import ProspectsAutocomplete from "../autocomplete/ProspectsAutocomplete";
import { FormProvider, useForm } from "react-hook-form";
import StatusChip from "@/common/components/status-chip";
import FormLabel from "@/common/components/form-label/FormLabel";
import { ProspectInfoBasic } from "@/common/components/prospect-info/ProspectInfoBasic";
import { dateFormatStr } from "@/common/functions/dateFormat";

interface ProspectInfoTypeProps extends Prospect {
  isInbox?: boolean;
}

const ProspectInfo = ({ isInbox = false }: ProspectInfoTypeProps) => {
  const theme = useTheme();
  const currentProspect = useAppSelector((state) => state.prospects.listProspects.currentProspect);
  const prospects = useAppSelector(({ prospects }) => prospects.listProspects.prospects);
  const dispatch = useAppDispatch();
  const prospectSequence = useAppSelector(({ sequences }) => sequences.listSequences.prospectSequence);
  const prospectDealList = useAppSelector(({ deals }) => deals.listDeals.dealsByContactId);
  const methods = useForm();

  const deal = useMemo(() => {
    if (!prospectDealList) return null;
    if (prospectDealList.length === 0) return null;
    if (prospectDealList.length === 1) return prospectDealList[0];
    // const sorted = prospectDealList.sort(() => );
    // TODO: Find first ACTIVE
    // const active = sorted.find(())
    // TODO: Find first
    return null;
  }, [prospectDealList]);

  const sequence = prospectSequence?.length > 0 ? prospectSequence[0] : null;
  const sequenceTriggeredAt = sequence?.triggeredAt ? dateFormatStr(sequence.triggeredAt) : null;

  useEffect(() => {
    if (currentProspect?.id) {
      dispatch(getDealsListByContactId(currentProspect?.id));
      dispatch(getSequencesByContact(currentProspect?.id));
    }
  }, [currentProspect, dispatch]);

  if (!prospects) return null;

  return (
    <Box
      sx={({ palette }) => ({
        background: isInbox ? palette.midnight[20] : null,
        width: "100%",
        overflowY: isInbox ? "auto" : "hidden",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        display: "inline-flex",
        gap: "24px",
        padding: isInbox ? "10px 10px" : "0px",
        height: isInbox ? "100%" : "90vh",
        borderRadius: "8px",
      })}
    >
      {currentProspect?.firstName && (
        <Box sx={{ width: "100%" }}>
          <FormProvider {...methods}>
            <form>
              <FormLabel label="Prospect" />
              <ProspectsAutocomplete disabled={isInbox} />
            </form>
          </FormProvider>
        </Box>
      )}
      <Accordion summaryTitle="Prospect" defaultExpanded id="Prospect-info">
        <ProspectInfoBasic prospect={currentProspect || {}} />
      </Accordion>

      {deal && (
        <Accordion summaryTitle="Deal" defaultExpanded id="deal">
          <Box
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "16px",
              display: "flex",
              paddingLeft: "4px",
            }}
          >
            <TitleAndSubtitle title="Deal Name" subtitle={deal.name} alternativeColor={theme.palette.link.default} />
            {deal.amount > 0 && (
              <Box
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                {deal.amount > 0 && <TitleAndSubtitle title="Value" subtitle={deal.amount} />}
                {/*   TODO: Not available in response. */}
                {/*<Box*/}
                {/*  sx={{*/}
                {/*    flexDirection: "column",*/}
                {/*    justifyContent: "flex-start",*/}
                {/*    alignItems: "flex-start",*/}
                {/*    gap: "6px",*/}
                {/*    display: "inline-flex",*/}
                {/*    width: "100%",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <Typography variant="body2" fontSize="12px" fontWeight="600" textTransform="uppercase">*/}
                {/*    Heat Score*/}
                {/*  </Typography>*/}
                {/*  <Box sx={{ display: "flex", alignItems: "center" }}>*/}
                {/*    <Fire />*/}
                {/*    <Typography variant="body2">5.0</Typography>*/}
                {/*  </Box>*/}
                {/*</Box>*/}
              </Box>
            )}
            <Box
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <TitleAndSubtitle title="Pipeline" subtitle={deal.pipelineId} />
              <TitleAndSubtitle title="Stage" subtitle={deal.pipelineStage.name} />
            </Box>
            {/* TODO: UX... we can have multiple Line Items but no mock. */}
            {deal.lineItems.length > 0 && <TitleAndSubtitle title="product" subtitle={deal.lineItems[0]} />}
          </Box>
        </Accordion>
      )}

      {sequence && (
        <Accordion summaryTitle="Sequence" defaultExpanded id="Sequence-info">
          <Box
            style={{
              alignSelf: "stretch",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "16px",
              display: "flex",
              paddingLeft: "4px",
            }}
          >
            <Box
              style={{
                alignSelf: "stretch",
                justifyContent: "space-between",
                alignItems: "flex-start",
                display: "inline-flex",
              }}
            >
              <TitleAndSubtitle title="Sequence Name" subtitle={sequence.name} customWidth={isInbox ? null : "80%"} />
              <StatusChip status={sequence.status || SequenceResponseStatusEnum.InProgress} type="sequence" />
            </Box>
            {sequenceTriggeredAt && <TitleAndSubtitle title="Date Enrolled" subtitle={sequenceTriggeredAt} />}

            <TitleAndSubtitle
              title="Progress of Sequence"
              subtitle={`${sequence.countStepsCompleted} out of ${sequence.countSteps}`}
            />
          </Box>
        </Accordion>
      )}
    </Box>
  );
};

export default ProspectInfo;

export const TitleAndSubtitle = ({
  title,
  subtitle,
  alternativeColor = null,
  customWidth = null,
}: {
  title?: string;
  subtitle?: string | JSX.Element;
  alternativeColor?: string | null;
  customWidth?: string | null;
}) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "8px",
        width: customWidth || "100%",
      }}
    >
      <Typography variant="body2" fontSize="12px" fontWeight="600" textTransform="uppercase">
        {title || "N/A"}
      </Typography>
      {alternativeColor ? (
        <Typography variant="body2" color={alternativeColor}>
          {subtitle || "N/A"}
        </Typography>
      ) : (
        <Typography variant="body2">{subtitle || "N/A"}</Typography>
      )}
    </Box>
  );
};
