import TableActionButton from "./ActionButton";
import StatusChip from "@/common/components/status-chip";
import TaskTableType from "./type";
import { Box, Button, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import IndeterminateCheckbox from "@/common/components/checkbox/IntermediateCheckbox";
import { TaskRequestStatusEnum } from "@/services/generated";
import dayjs from "dayjs";
import { SortAscending, SortDescending } from "heroicons-react";
import { Bars4Icon } from "@heroicons/react/24/solid";
import { TaskFiltersProps } from "../filters/TaskFilters";

const columnHelper = createColumnHelper<any>();

export const TaskColumns = ({ filters, setFilters }: TaskFiltersProps) => {
  const updateSort = () => {
    if (filters?.sortBy === "dueDate") {
      if (filters?.sortDirection === "asc") {
        setFilters((previousFilters) => ({ ...previousFilters, sortBy: "createdAt", sortDirection: "desc" }));

        return;
      }
      setFilters((previousFilters) => ({ ...previousFilters, sortBy: "dueDate", sortDirection: "asc" }));

      return;
    }

    setFilters((previousFilters) => ({ ...previousFilters, sortBy: "dueDate", sortDirection: "desc" }));
  };

  return [
    columnHelper.accessor("checkbox", {
      header: ({ table }) => (
        <div
          style={{
            display: "flex",
            gap: "17px",
            alignItems: "center",
            margin: "0 auto",
            maxWidth: "0px",
          }}
        >
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),

      cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "17px",
              alignItems: "center",
              margin: "0 auto",
              width: "0px",
              maxWidth: "0px",
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    }),

    columnHelper.accessor("type", {
      header: () => <Typography variant="body3">Type</Typography>,

      cell: (info) => {
        const type = info.getValue();

        return (
          <div style={{ maxWidth: "350px", cursor: "pointer" }}>
            <TaskTableType
              action={type}
              prospectName={info.row.original.prospect?.firstName + " " + info.row.original.prospect?.lastName}
            />
          </div>
        );
      },
    }),

    columnHelper.accessor("assignee.name", {
      header: () => <Typography variant="body3">Assignee</Typography>,
      cell: (info) => {
        const assignee = info.getValue();
        return (
          <div style={{ cursor: "pointer" }}>
            <Typography variant="body2">{assignee || "Unassigned"}</Typography>
          </div>
        );
      },
    }),
    columnHelper.accessor("dueDate", {
      header: () => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ cursor: "pointer" }} variant="body3">
            Due Date
          </Typography>
          <Button variant="text" onClick={() => updateSort()}>
            {filters?.sortDirection === "asc" ? (
              <SortAscending width={24} color="gray" />
            ) : filters?.sortDirection === "desc" && filters?.sortBy === "dueDate" ? (
              <SortDescending width={24} color="gray" />
            ) : (
              <Bars4Icon width={24} color="gray" />
            )}
          </Button>
        </Box>
      ),
      cell: (info) => {
        const dueDate = info.getValue();
        return (
          <div style={{ cursor: "pointer" }}>
            <Typography variant="body2">{dayjs(dueDate).format("MMM DD, YYYY h:mm A")}</Typography>
          </div>
        );
      },
    }),
    columnHelper.accessor("status", {
      header: () => <Typography variant="body3">Status</Typography>,
      cell: (info) => {
        const status = info.getValue() || TaskRequestStatusEnum.Due;

        return (
          <div style={{ cursor: "pointer" }}>
            <StatusChip status={status as TaskRequestStatusEnum} type="task" />
          </div>
        );
      },
    }),

    columnHelper.display({
      id: "actions",
      cell: (props) => <TableActionButton row={props.row} />,
    }),
  ];
};
