import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown/Dropdown";
import EmptyState from "@/common/components/empty-state/EmptyState";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import PageHeader from "@/common/components/page-header/PageHeader";
import routes from "@/common/constants/routes";
import { getDeals, openCreateDealDrawer } from "@/redux/reducers/dealFlow/slices/listDeals";
import { getPipelineDetails, getPipelines } from "@/redux/reducers/dealFlow/slices/listPipelines";
import { DealFiltersInput, Pipeline } from "@/redux/reducers/dealFlow/slices/types";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import { selectDealCardsByFilters } from "@/redux/selectors/deal-flow";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { TableCellsIcon } from "@heroicons/react/24/outline";
import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import CreateDrawer from "./create/CreateDrawer";
import Filters from "./filters/Filters";
import KanbanBoard from "./kanban/KanbanBoard";
import SettingsMenu from "./settings-menu/SettingsMenu";
import Table from "./table";

export default function DealFlowPage() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: currentPipelineId } = useParams<{ id?: string }>();
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines);
  const teamUsers = useAppSelector(({ team }) => team.listUsers.users);
  const loadingTeamUsers = useAppSelector(({ team }) => team.listUsers.loading);
  const loadingCards = useAppSelector(({ dealFlow }) => dealFlow.listDeals.loading);
  const createDealLoading = useAppSelector(({ dealFlow }) => dealFlow.createDeal.loading);
  const loadingPipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.loading);
  const [filters, setFilters] = useState<DealFiltersInput>({
    viewType: "kanban",
  });

  const hasFilters = Object.keys(filters).some((key) =>
    key !== "viewType" ? filters[key as keyof DealFiltersInput] !== undefined : false,
  );

  const handleFilters = (newFilters: DealFiltersInput) => setFilters((filters) => ({ ...filters, ...newFilters }));

  const filteredCards = useAppSelector((selector) => selectDealCardsByFilters(selector, filters));

  useEffect(() => {
    if (currentPipelineId) {
      dispatch(getDeals(currentPipelineId));
    }
  }, [currentPipelineId, dispatch]);

  useEffect(() => {
    if (pipelines?.length && !currentPipelineId) {
      navigate(routes.dealFlow.details.with({ id: pipelines[0].id }));
    }
  }, [dispatch, pipelines, navigate, currentPipelineId]);

  useEffect(() => {
    if (!currentPipelineId) {
      return;
    }
    const currentPipeline = pipelines?.find((pipeline: Pipeline) => pipeline.id === currentPipelineId);
    if (currentPipeline && currentPipeline.stages === undefined) {
      dispatch(getPipelineDetails(currentPipelineId));
    }
  }, [currentPipelineId, dispatch, pipelines]);

  const pipelineStages = useMemo(() => {
    if (currentPipelineId && pipelines?.length) {
      const currentPipeline = pipelines?.find((pipeline: Pipeline) => pipeline.id === currentPipelineId);
      if (currentPipeline && currentPipeline.stages === undefined) {
        return [];
      }
      if (currentPipeline?.stages) {
        return currentPipeline.stages;
      }
    }
    return [];
  }, [pipelines, currentPipelineId]);

  const handleEditPipeline = useCallback(() => {
    if (currentPipelineId) {
      navigate(routes.dealFlow.update.with({ id: currentPipelineId }));
      return;
    }
  }, [navigate, currentPipelineId]);

  useEffect(() => {
    if (pipelines === undefined && !loadingPipelines) {
      dispatch(getPipelines());
    }
    if (teamUsers === undefined && !loadingTeamUsers) {
      dispatch(getTeamUsers());
    }
  }, [dispatch, teamUsers, pipelines, loadingPipelines, loadingTeamUsers]);

  const pipelineOptions = useMemo(
    () =>
      (pipelines || []).map((pipeline) => ({
        value: pipeline.id,
        label: pipeline.name,
      })),
    [pipelines],
  );

  if (loadingCards || loadingPipelines) {
    return <FullLoadingOverlay loading />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <FullLoadingOverlay loading={createDealLoading} overlay={true} />
      <PageHeader
        title={t("dealFlow.title")}
        actions={
          <Box sx={{ display: "flex", gap: 1 }}>
            <Dropdown
              label="Select Pipeline"
              placeholder="Select Pipeline"
              value={currentPipelineId}
              options={pipelineOptions}
              isSearchable
              onChange={(option) => {
                navigate(routes.dealFlow.details.with({ id: option.value }));
              }}
            />
            <SettingsMenu onEditPipeline={handleEditPipeline} />
            <Button
              size="sm"
              onClick={() => dispatch(openCreateDealDrawer({ deal: { pipelineId: currentPipelineId } }))}
            >
              {t("dealFlow.createDrawer.title")}
            </Button>
          </Box>
        }
        filters={<Filters filters={filters} onSetFilters={handleFilters} />}
      />

      {filteredCards.length === 0 && (
        <>
          {hasFilters ? (
            <EmptyState
              title={hasFilters ? "No deals found that match your criteria" : "No deals found"}
              description={
                <span>
                  Try a different search parameter or simply <br /> clear all the filters with the button below.
                </span>
              }
              Icon={TableCellsIcon}
            />
          ) : filters.viewType === "kanban" ? (
            <KanbanBoard stages={pipelineStages} cards={filteredCards} loadingCards={loadingCards} />
          ) : (
            <Table cards={filteredCards} />
          )}
        </>
      )}

      {filters.viewType === "kanban" && !!filteredCards.length && (
        <KanbanBoard stages={pipelineStages} cards={filteredCards} loadingCards={loadingCards} />
      )}

      {filters.viewType === "list" && !!filteredCards.length && <Table cards={filteredCards} />}
      <CreateDrawer />
    </DndProvider>
  );
}
