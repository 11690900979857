import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ProspectFinderFilters from "./ProspectFinderFilters";
import ProspectFinderTable from "./ProspectFinderTable";
import AdvancedFilters from "./AdvancedFilters";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { usePagination } from "@/common/components/pagination/usePagination";
import PageHeader from "@/common/components/page-header/PageHeader";
import { ReactComponent as InformationCircleIcon } from "@/common/assets/svg/information_circle.svg";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { getProspectFinderContacts } from "@/redux/reducers/prospects/finder/slices/listProspectFinder";
import { KnCompany } from "@/services/generated";

export type ProspectFinderFiltersType = {
  prospectName?: string;
  company?: KnCompany;
  location?: {
    city?: string;
    state?: string;
    country?: string;
    postal_code?: string;
  };
  jobTitle?: string;
  industry?: string;
};

export default function ProspectFinder() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchString, setSearchString] = useState("");
  const [advancedFiltersDrawerOpen, setAdvancedFiltersDrawer] = useState(false);
  const contacts = useAppSelector((state) => state.prospectFinder.listProspectsFinder?.contacts);
  const loading = useAppSelector((state) => state.prospectFinder.listProspectsFinder?.loading);
  const totalCount = useAppSelector((state) => state.prospectFinder.listProspectsFinder?.totalCount);

  const { onPaginationChange, pagination } = usePagination();
  const dispatch = useAppDispatch();
  const memoizedContacts = React.useMemo(() => contacts || [], [contacts]);
  const memoizedTotalCount = React.useMemo(() => totalCount || 0, [totalCount]);
  const memoizedOnPaginationChange = React.useCallback(onPaginationChange, [onPaginationChange]);

  const initialState = {
    prospectName: "",
    companyDomain: "",
    // location: "",
    jobTitle: "",
    industry: "",
  };

  const [filters, setFilters] = useState<ProspectFinderFiltersType>(initialState);

  const toggleAdvancedFilters = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setAdvancedFiltersDrawer(open);
    },
    [],
  );

  useEffect(() => {
    dispatch(
      getProspectFinderContacts({
        // TODO: You can go back to spread syntax as long as location city/state split works.
        companyDomain: filters.company?.domain,
        prospectName: filters.prospectName,
        corporateCity: filters.location?.city,
        corporateState: filters.location?.state,
        jobTitle: filters?.jobTitle,
        companyIndustry: filters?.industry,
        page: pagination.pageIndex,
        pageSize: pagination.pageSize,
      }),
    );
  }, [dispatch, pagination.pageIndex, pagination.pageSize, filters]);

  const NotFoundData: React.FC = () => (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px" }}>
      <InformationCircleIcon height="42px" width="42px" color={theme.palette.icon.informative} />
      <Typography sx={{ fontSize: "16px", lineHeight: "24px", textAlign: "center" }}>
        Type a search parameter in the search field <br /> above to see the results
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <PageHeader
            title={t("prospects.finderTitle")}
            filters={
              <ProspectFinderFilters
                // toggleAdvancedFilters={toggleAdvancedFilters}
                // setSearchString={setSearchString}
                setFilters={setFilters}
                filters={filters}
              />
            }
          />

          <ProspectFinderTable
            contacts={memoizedContacts}
            totalCount={memoizedTotalCount}
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            onPaginationChange={memoizedOnPaginationChange}
          />

          <Drawer open={advancedFiltersDrawerOpen} onClose={toggleAdvancedFilters(false)} anchor="right">
            <AdvancedFilters toggleDrawer={toggleAdvancedFilters(false)} />
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
}
