import * as React from "react";
import {
  setCurrentProspect,
  deleteProspect,
  openEditDrawer,
  showSeqEnrollDialog,
} from "@/redux/reducers/prospects/slices/listProspects";
import { useAppDispatch } from "@/redux/store";
import { ReactComponent as PencilSquare } from "@/common/assets/svg/tasks/edit.svg";
import { ReactComponent as SquaresPlus } from "@/common/assets/svg/sidebar/squares-plus.svg";
import { ReactComponent as Trash } from "@/common/assets/svg/tasks/trash.svg";
import { Typography } from "@mui/material";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import MenuOptions from "@/common/components/menu-options/MenuOptions";

const iconStyles = {
  width: "24px",
  height: "24px",
  stroke: "#1A2135",
};

const options = [
  {
    label: "Enroll in Sequence",
    value: "enroll_in_sequence",
    icon: <SquaresPlus style={iconStyles} />,
  },
  {
    label: "Edit Prospect",
    value: "edit",
    icon: <PencilSquare style={iconStyles} />,
  },
  {
    label: "Delete Prospect",
    value: "delete",
    icon: <Trash style={iconStyles} />,
  },
];

export default function TableActionButton({ params }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { deleteConfirm } = useConfirmModal();

  return (
    <MenuOptions
      open={open}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      options={options}
      onClick={(option) => {
        if (option.value === "enroll_in_sequence") {
          params.row.toggleSelected(true);
          // dispatch(setSeqEnrollContactIdList([params.row.original.id]));
          dispatch(showSeqEnrollDialog());
          handleClose();
        } else if (option.value === "delete") {
          deleteConfirm({
            textDiv: (
              <>
                <Typography variant="body1">Are you sure you want to delete this prospect?</Typography>
                <Typography variant="body1">This action cannot be undone.</Typography>
              </>
            ),
          })
            .then(() => {
              dispatch(deleteProspect(params.row.original.id));
            })
            .catch(() => {
              return;
            });

          handleClose();
        } else {
          dispatch(setCurrentProspect(params.row.original));
          dispatch(openEditDrawer());
          handleClose();
        }
      }}
    />
  );
}
