import { Theme } from "@mui/material";
import { StylesConfig } from "react-select";

export const getDropdownStyles = (
  theme: Theme,
  error?: string | null,
  menuMinWidth?: string,
): StylesConfig<any, boolean> => ({
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    padding: "9px 16px",

    fontSize: "14px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.2px",
    borderRadius: "8px",
    borderColor: theme.palette.commonColors.light,
    boxShadow: "none",
    margin: "0px",
    color: theme.palette.txt.default,
    minWidth: "max-content",
    boxSizing: "border-box",
    height: "44px",

    "&:hover": {
      background: "#F8F8F8",
    },

    border: `1px solid ${error ? theme.palette.border.danger : theme.palette.commonColors.light}`,
  }),

  multiValue: (provided) => ({
    ...provided,
    padding: "0px",
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0px",
    color: theme.palette.midnight.p,

    "&:hover": {
      color: theme.palette.midnight.p,
    },
  }),

  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    marginTop: "2px",
    maxWidth: "264px",
    minWidth: menuMinWidth,
    borderRadius: "8px",
  }),

  menuList: (provided) => ({
    padding: "6px",
    maxHeight: "462px",
    overflowY: "auto",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? theme.palette.grey.A100
      : state.isFocused
      ? theme.palette.action.hover
      : theme.palette.common.white,
    color: state.isDisabled ? theme.palette.grey.A400 : theme.palette.txt.default,
    fontFamily: theme.typography.fontFamily,
    fontSize: "14px",
    fontWeight: 400,
    padding: "8px",
    borderBottom: `1px solid ${theme.palette.commonColors.light}`,
    cursor: state.isDisabled ? "not-allowed" : "default",

    ":after": {
      content: state.data.subtext && `'${state.data.subtext}'`,
    },

    "&:last-child": {
      borderBottom: "none",
    },

    "&:active": {
      backgroundColor: theme.palette.action.selected,
    },
  }),

  placeholder: (provided) => ({
    ...provided,
    color: theme.palette.txt.placeholder,
    minWidth: "max-content",
    fontSize: "14px",
  }),
});
