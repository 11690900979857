import TableActionButton from "./ActionButton";
import { createColumnHelper } from "@tanstack/react-table";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { SequenceTemplate } from "@/types/sequence";
import StatusBadge from "../sequence-status";

const columnHelper = createColumnHelper<SequenceTemplate>();

export const SequenceColumns = [
  columnHelper.accessor("name", {
    header: () => (
      <div style={{ marginLeft: "16px" }}>
        <Typography variant="body3" fontWeight="600">
          Sequence Name
        </Typography>
      </div>
    ),

    cell: (info) => {
      return (
        <div style={{ marginLeft: "16px" }}>
          <Typography variant="body2">{info.getValue()}</Typography>
        </div>
      );
    },
  }),

  columnHelper.accessor("createdBy", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Created By
      </Typography>
    ),
    cell: (info) => {
      const createdBy = info.getValue();
      if (!createdBy) {
        return;
      }
      return <Typography variant="body2">{createdBy.name}</Typography>;
    },
  }),

  /*
  columnHelper.accessor("replyRate", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Reply Rate
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()}</Typography>;
    },
  }),
  */

  columnHelper.accessor("prospectEnrolled", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Prospect Enrolled
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{info.getValue()}</Typography>;
    },
  }),

  columnHelper.accessor("status", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Status
      </Typography>
    ),
    cell: (info) => {
      const status = info.getValue();
      const versionsLength = info.row.original.versions?.length || 0;
      const numRemainingVersions = versionsLength > 1 ? versionsLength - 1 : 0;
      if (!status) {
        return;
      }
      return <StatusBadge status={status} numRemainingVersions={numRemainingVersions} />;
    },
  }),

  columnHelper.accessor("createdAt", {
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Created Date
      </Typography>
    ),
    cell: (info) => {
      return <Typography variant="body2">{dayjs(info.getValue()).format("MMM DD, YYYY hh:mm A")}</Typography>;
    },
  }),

  columnHelper.display({
    id: "actions",
    header: () => (
      <Typography variant="body3" fontWeight="600">
        Actions
      </Typography>
    ),
    cell: (props) => <TableActionButton params={props} />,
  }),
];
