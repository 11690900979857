import { ReactComponent as LogIcon } from "@/common/assets/svg/log.svg";
import { ReactComponent as SaveIcon } from "@/common/assets/svg/save.svg";
import Button from "@/common/components/button";
import FormLabel from "@/common/components/form-label/FormLabel";
import IconButton from "@/common/components/icon-button";
import Input from "@/common/components/input/Input";
import LoadingOverlay from "@/common/components/loading-overlay";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import SequenceAction from "@/common/components/sequence-action/SequenceAction";
import { SequenceConnection } from "@/common/components/sequence-connection";
import Summary from "@/common/components/summary/Summary";
import routes from "@/common/constants/routes";
import useConfirmModal from "@/common/hooks/useConfirmModal";
import toast from "@/lib/toast";
import CreateAiVoice from "@/pages/sequence-builder/create-ai-voice/CreateAiVoice";
import DraftTemplateEmail from "@/pages/sequence-builder/send-email/DraftTemplateEmail";
import {
  createSequence,
  createStepTemplate,
  removeSequenceStepTemplate,
  switchConsecutiveStepsOrder,
  updateSequence,
  updateStepTemplateBehavior,
  updateStepTemplateInfo,
} from "@/redux/reducers/sequences/slices/createSequence";
import { getSequenceById } from "@/redux/reducers/sequences/slices/listSequences";
import { getCurrentSequence } from "@/redux/selectors/sequences";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { StepTemplateRequestCreateTypeEnum, StepTemplateResponse } from "@/services/generated";
import { StepTemplate } from "@/types/sequence";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Box, Divider, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import StatusBadge from "../sequence-status";
import SequenceVersions from "../versions";

interface summaryProps {
  totalSteps: number;
  daysToComplete: number;
  automation: number;
}

const getSummarySequenceData = ({ totalSteps, daysToComplete, automation }: summaryProps) => [
  { title: "Total Steps", value: totalSteps.toString() },
  { title: "Days to Complete", value: daysToComplete.toString() },
  { title: "Automation", value: automation.toString() + "%" },
];

const getDaysToComplete = (steps: StepTemplate[]) => {
  return steps.reduce((acc: number, item: StepTemplate) => {
    return acc + (item.delay || 0);
  }, 0);
};

export default function CreateSequencePage() {
  const params = useParams();
  const currentSequence = useAppSelector(getCurrentSequence);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // TODO: Refactor and use `useFieldsArray` for actions and add form validations
  const formMethods = useForm({
    defaultValues: {
      name: "",
    },
  });
  const { control, setValue, getValues, watch } = formMethods;
  const [actions, setActions] = useState<StepTemplate[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenAiVoice, setDrawerOpenAiVoice] = useState(false);
  const [versionsDrawerOpen, setVersionsDrawerOpen] = useState<boolean>(false);
  const { deleteConfirm } = useConfirmModal();
  const [isLoadingCurrentSequence, setIsLoadingCurrentSequence] = useState<boolean>(false);

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const currentStep = useMemo(() => actions[currentStepIndex], [actions, currentStepIndex]);

  const canUpdateSequence = useMemo(
    () => !currentSequence || (currentSequence?.status && !["ACTIVE", "INACTIVE"].includes(currentSequence?.status)),
    [currentSequence],
  );

  const sequenceName = watch("name");
  const totalSteps = actions.length;
  const daysToComplete = useMemo(() => getDaysToComplete(actions), [actions]);

  const { loading: pendingCreateSequence } = useAppSelector(({ sequences }) => sequences.createSequence);

  const summarySequenceData = useMemo(() => {
    const automation =
      Math.ceil(
        (actions.filter((action) => !["CALL", "MANUAL", undefined].includes(action.actionType)).length / totalSteps) *
          100,
      ) || 0;
    return getSummarySequenceData({ totalSteps, daysToComplete, automation });
  }, [totalSteps, actions, daysToComplete]);

  useEffect(() => {
    async function fetchCurrentSequence() {
      if (!params.id) {
        return;
      }

      setIsLoadingCurrentSequence(true);

      try {
        await dispatch(getSequenceById({ id: params.id }));

        navigate(routes.sequence.update.with({ id: params.id }));
      } catch (error) {
        toast.error("Something went wrong!");
      } finally {
        setIsLoadingCurrentSequence(false);
      }
    }

    fetchCurrentSequence();
  }, [dispatch, navigate, params.id]);

  useEffect(() => {
    setValue("name", currentSequence?.name || "");

    const steps = (currentSequence?.steps as StepTemplate[]) || [];

    const stepsOrder = order(steps);

    setActions(stepsOrder);
  }, [currentSequence]);

  const order = (steps: StepTemplate[]): StepTemplate[] => {
    if (steps.length === 0) {
      return [];
    }
    if (steps.length === 1) {
      return steps;
    }
    const first = steps.find((s) => s.parentsIds?.length === 0);
    if (!first) {
      throw new Error("Invalid State: If production, send URL to support@salesai.com");
    }
    const ordered = [first];
    let parentId = first?.id;
    // Keep finding children
    while (true) {
      const child = steps.find((s) => (s.parentsIds ? s.parentsIds[0] : "bad") === parentId);
      if (!child) {
        break;
      }
      ordered.push(child);
      parentId = child.id;
    }

    return ordered;
  };
  const handleCancel = () => {
    navigate(routes.sequence.path);
  };

  const handleDelete = () => {
    if (!currentSequence) {
      return;
    }

    deleteConfirm({})
      .then(() => {
        dispatch(updateSequence({ ...currentSequence, status: "DELETED" }));

        toast.success(`Sequence deleted successfully!`);

        navigate(routes.sequence.path);
      })
      .catch(() => {
        return;
      });
  };

  const handleActivate = () => {
    if (!currentSequence) {
      return;
    }

    const newSequence = getValues();

    dispatch(updateSequence({ ...newSequence, id: currentSequence.id, status: "ACTIVE" }));

    toast.success(`Sequence activated successfully!`);
  };

  const handleSave = () => {
    const newSequence = getValues();

    const willCreateSequence = !currentSequence;

    if (willCreateSequence) {
      dispatch(createSequence(newSequence))
        .unwrap()
        .then((sequence) => {
          if (!sequence) {
            return;
          }

          navigate(routes.sequence.update.with({ id: sequence.id }));

          toast.success(`Sequence ${willCreateSequence ? "created" : "updated"}  successfully`);
        });
    } else {
      dispatch(updateSequence({ id: currentSequence.id, status: currentSequence.status, ...newSequence })).then(() => {
        navigate(routes.sequence.path);

        toast.success(`Sequence ${willCreateSequence ? "created" : "updated"}  successfully`);
      });
    }
  };

  const handleAddStep = (index: number) => {
    if (!actions[index].id) {
      return;
    }
    const newSequenceData = [...actions];
    const newStepParentId = actions[index].id;
    const newStep = newStepParentId ? { parentsIds: [newStepParentId] } : {};
    newSequenceData.splice(index + 1, 0, newStep);
    setActions(newSequenceData);
  };

  const handleSelectActionType = (index: number, actionType: StepTemplate["actionType"]) => {
    setCurrentStepIndex(index);
    if (actionType === "CALL") {
      const newSequenceData = [...actions];
      if (!newSequenceData[index]) {
        newSequenceData[index] = {};
      }
      newSequenceData[index].actionType = actionType;
      setActions(newSequenceData);
      setDrawerOpenAiVoice(true);
      return;
    }
    const newSequenceData = [...actions];
    if (!newSequenceData[index]) {
      newSequenceData[index] = {};
    }
    newSequenceData[index].actionType = actionType;
    setActions(newSequenceData);
    setDrawerOpen(true);
  };

  const handleAddAction = async (data: StepTemplate) => {
    if (!currentSequence?.id) return;

    setDrawerOpen(false);
    setDrawerOpenAiVoice(false);

    const newSequenceData = [...actions];
    const step = { ...currentStep, ...data };

    if (currentStepIndex !== -1) {
      newSequenceData[currentStepIndex] = step;
    } else {
      newSequenceData.push(step);
    }

    // TODO not including ai agent
    const stepData = {
      id: currentStep?.id,
      sequenceTemplateId: currentSequence.id,
      type: step.actionType,
      parentsIds: step.parentsIds,
      ...(step.actionType === "CALL"
        ? { agentId: step.aiAgentId }
        : {
            messageTemplate: step.content,
            subjectTemplate: step.subject,
          }),
    };

    let response;
    if (!!currentStep?.id) {
      response = await dispatch(updateStepTemplateInfo(stepData)).unwrap();
    } else {
      response = await dispatch(createStepTemplate(stepData)).unwrap();
    }

    if (!response.id) return;

    newSequenceData[currentStepIndex].id = response.id;

    if (!currentStep.id && newSequenceData[currentStepIndex + 1]) {
      const nextStep = { ...newSequenceData[currentStepIndex + 1] };

      nextStep.parentsIds = [response.id];

      dispatch(
        updateStepTemplateBehavior({
          stepTemplateId: nextStep.id,
          parentsIds: [response.id],
        }),
      );
    }

    setActions(newSequenceData);
  };

  const handleUpdateAction = (id: string) => {
    const index = actions.findIndex((action) => action.id === id);
    const step = actions[index];
    setCurrentStepIndex(index);
    if (step.actionType === "CALL") {
      // .. set action type?
      setDrawerOpenAiVoice(true);
    } else {
      setDrawerOpen(true);
    }
  };

  const handleMoveUp = (id: string) => {
    const index = actions.findIndex((item) => item.id === id);

    if (index <= 0) return;

    const prevIndex = index - 1;
    const currIndex = index;
    const prevStep = actions[prevIndex];
    const currStep = actions[currIndex];

    const updtPrevStep = {
      ...currStep,
      parentsIds: prevIndex === 0 ? [] : prevStep.parentsIds,
    };

    const updtCurrStep = {
      ...prevStep,
      parentsIds: [updtPrevStep.id!],
    };

    dispatch(
      switchConsecutiveStepsOrder({
        stepId1: updtCurrStep.id!,
        stepId2: updtPrevStep.id!,
      }),
    );

    const updtActions = [...actions];
    updtActions[prevIndex] = updtPrevStep;
    updtActions[currIndex] = updtCurrStep;

    setActions(updtActions);
  };

  const handleMoveDown = (id: string) => {
    const index = actions.findIndex((item) => item.id === id);

    if (index === -1 || index === actions.length - 1) return;

    const currIndex = index;
    const nextIndex = index + 1;
    const currStep = actions[currIndex];
    const nextStep = actions[nextIndex];

    const updtCurrStep = {
      ...nextStep,
      parentsIds: currStep.parentsIds,
    };

    const updtNextStep = {
      ...currStep,
      parentsIds: [updtCurrStep.id!],
    };

    dispatch(
      switchConsecutiveStepsOrder({
        stepId1: updtCurrStep.id!,
        stepId2: updtNextStep.id!,
      }),
    );

    const updtActions = [...actions];
    updtActions[currIndex] = updtCurrStep;
    updtActions[nextIndex] = updtNextStep;

    setActions(updtActions);
  };

  const handleDuplicateAction = async (id: string) => {
    try {
      if (!currentSequence?.id) {
        return;
      }

      const index = actions.findIndex((item) => item.id === id);
      const newSequenceData = [...actions];
      const currentAction = newSequenceData[index];
      const type = currentAction.actionType as StepTemplateRequestCreateTypeEnum;

      const stepData = {
        sequenceTemplateId: currentSequence.id,
        type,
        delayInMillis: (currentAction.delay || 0) * (1000 * 60 * 60 * 24),
        messageTemplate: currentAction.content,
        parentsIds: [id],
        ...(type === "CALL"
          ? { agentId: currentAction.aiAgentId }
          : {
              messageTemplate: currentAction.content,
              subjectTemplate: currentAction.subject,
            }),
      };

      const response = await dispatch(createStepTemplate(stepData));

      const newStepId = (response.payload as StepTemplateResponse).id!;

      const nextStep = newSequenceData[index + 1];

      if (nextStep) {
        newSequenceData[index + 1] = { ...nextStep, parentsIds: [newStepId] };

        await dispatch(
          updateStepTemplateBehavior({
            stepTemplateId: newSequenceData[index + 1].id,
            parentsIds: [newStepId],
          }),
        );
      }

      newSequenceData.splice(index + 1, 0, { ...currentAction, parentsIds: [id], id: newStepId });

      setActions(newSequenceData);
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleSetDelay = async (id: string, delay: number) => {
    try {
      if (!currentSequence?.id) {
        return;
      }

      const index = actions.findIndex((item) => item.id === id);
      const newSequenceData = [...actions];
      const currentAction = newSequenceData[index];
      const type = currentAction.actionType as StepTemplateRequestCreateTypeEnum;

      await dispatch(
        updateStepTemplateInfo({
          id,
          type,
          delayInMillis: delay * (1000 * 60 * 60 * 24),
          messageTemplate: currentAction.content,
          ...(type === "CALL"
            ? { agentId: currentAction.aiAgentId }
            : {
                messageTemplate: currentAction.content,
                subjectTemplate: currentAction.subject,
              }),
        }),
      );

      newSequenceData[index] = { ...currentAction, delay };

      setActions(newSequenceData);

      toast.success("Step delay updated successfully");
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleRemoveAction = async (id: string) => {
    await dispatch(removeSequenceStepTemplate(id));

    const index = actions.findIndex((item) => item.id === id);
    const newSequenceData = [...actions];
    const nextStep = newSequenceData[index + 1];
    const parentsIds = index > 0 ? newSequenceData[index].parentsIds : [];

    if (nextStep) {
      await dispatch(
        updateStepTemplateBehavior({
          stepTemplateId: nextStep.id,
          parentsIds: parentsIds,
        }),
      );

      newSequenceData[index + 1] = { ...nextStep, parentsIds };
    }

    newSequenceData.splice(index, 1);

    setActions(newSequenceData);

    toast.success("Step Template Removed Successfuly.");
  };

  const handleRemoveDraftAction = (index: number) => {
    const newSequenceData = [...actions];
    newSequenceData.splice(index, 1);
    setActions(newSequenceData);
  };

  if (isLoadingCurrentSequence) return <LoadingOverlay />;

  return (
    <FormProvider {...formMethods}>
      <FullLoadingOverlay loading={pendingCreateSequence} overlay={true} />
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", marginY: "15px" }}>
        <Box
          sx={{
            display: "flex",
            gap: "25px",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000000",
            }}
          >
            Sequence Builder
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          {currentSequence && (
            <>
              <IconButton variant="danger" onClick={handleDelete}>
                <TrashIcon width={24} />
              </IconButton>
              <IconButton
                variant="secondary"
                onClick={handleSave}
                disabled={!canUpdateSequence || (!!currentSequence && actions.length === 0) || !sequenceName}
              >
                <SaveIcon />
              </IconButton>
              <IconButton variant="secondary" onClick={() => setVersionsDrawerOpen(true)}>
                <LogIcon width={24} />
              </IconButton>
            </>
          )}

          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          {currentSequence && (
            <Button variant="primary" disabled={!actions.length || !canUpdateSequence} onClick={handleActivate}>
              Activate
            </Button>
          )}
          {!currentSequence && (
            <Button
              variant="primary"
              onClick={handleSave}
              disabled={!canUpdateSequence || (!!currentSequence && actions.length === 0) || !sequenceName}
            >
              {actions.length === 0 ? "Next" : "Create Sequence"}
            </Button>
          )}
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {currentSequence && (
          <Box
            sx={{
              display: "flex",
              marginTop: 3,
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: "28px",
                letterSpacing: "0.2px",
                color: "#1A2135",
                marginBottom: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Summary
              {currentSequence?.status && <StatusBadge status={currentSequence.status} />}
            </Typography>
            <Summary title="Summary" data={summarySequenceData} />
          </Box>
        )}

        <Box sx={{ width: "100%", maxWidth: "654px", marginTop: 3 }}>
          <FormControl sx={{ width: "100%" }}>
            <Controller
              name="name"
              control={control}
              disabled={!canUpdateSequence}
              render={({ field, fieldState }) => (
                <>
                  <FormLabel aria-required label="Sequence Title" />
                  <Input {...field} placeholder="Type here your sequence name" error={fieldState?.error?.message} />
                </>
              )}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            maxWidth: "774px",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {actions.map((action, index) => (
            <Box key={`${index}-${action.id}`}>
              <SequenceAction
                {...action}
                aiAgentId={action.aiAgentId}
                canClose
                canUpdateSequence={canUpdateSequence}
                onSelectActionType={(type) => handleSelectActionType(index, type)}
                onUpdatedAction={() => action.id && handleUpdateAction(action.id)}
                onMoveUp={handleMoveUp}
                onMoveDown={handleMoveDown}
                onDuplicateAction={handleDuplicateAction}
                onSetDelay={handleSetDelay}
                onRemoveAction={handleRemoveAction}
                onRemoveDraftAction={handleRemoveDraftAction}
                index={index}
              />
              <Box sx={{ mt: 1 }}>
                <SequenceConnection
                  canUpdateSequence={canUpdateSequence}
                  isLastOne={index + 1 === actions.length}
                  onClick={() => handleAddStep(index)}
                />
              </Box>
            </Box>
          ))}

          {currentSequence && actions.length === 0 && (
            <SequenceAction index={0} onSelectActionType={(type) => handleSelectActionType(0, type)} />
          )}
        </Box>
      </Box>

      {currentSequence?.versions && (
        <SequenceVersions
          open={versionsDrawerOpen}
          sequence={currentSequence}
          versions={currentSequence?.versions ?? []}
          onClose={() => setVersionsDrawerOpen(false)}
        />
      )}

      {drawerOpen && (
        <DraftTemplateEmail
          isNew={!currentStep?.id}
          messageType={currentStep?.actionType}
          isOpen={drawerOpen}
          closeDrawer={() => setDrawerOpen(false)}
          onAddAction={handleAddAction}
          content={currentStep?.content}
          subject={currentStep?.subject}
        />
      )}

      {drawerOpenAiVoice && (
        <CreateAiVoice
          isOpen={drawerOpenAiVoice}
          closeDrawer={() => setDrawerOpenAiVoice(false)}
          onAddAction={handleAddAction}
        />
      )}
    </FormProvider>
  );
}
